<template>

  <!-- Лоадер -->
  <loader-start v-if="activeLoaderStart"></loader-start>

  <!-- Политика -->
  <transition name="slide-fade">
    <modal-policy v-if="pathModalPolicy"></modal-policy>
  </transition>

  <!-- Информация -->
  <transition name="slide-fade">
    <modal-help v-if="pathModalHelp"></modal-help>
  </transition>

  <!-- Превью заявки -->
  <transition name="slide-fade">
    <preview-order v-if="pathPageOrder"></preview-order>
  </transition>

  <!-- Главная страница -->
  <!-- v-show потому что это главная страница, работаем в стиле spa -->
  <page-main v-show="pathPageMain"></page-main>

</template>
<script>

import PageMain from "@/pages/PageMain";
import LoaderStart from "@/shared/ui/LoaderStart";
import {mapGetters} from "vuex";
import ModalHelp from "@/pages/ModalHelp";
import PreviewOrder from "@/pages/PreviewOrder";
import ModalPolicy from "@/pages/ModalPolicy";

export default {
  name: 'App',
  components: {
    ModalPolicy,
    PreviewOrder,
    ModalHelp,
    LoaderStart,
    PageMain,
  },
  data() {
    return {
      activeLoaderStart: true,
      activePreview: false,
      pathPageMain: true,
    }
  },
  computed:{
    ...mapGetters(['isPathModalHelp','isPathOrder', 'isPathModalPolicy']),
    pathModalPolicy(){
      return this.isPathModalPolicy
    },
    pathModalHelp(){
      return this.isPathModalHelp
    },
    pathPageOrder(){
      return this.isPathOrder
    }
  },
  mounted() {
    // Таймер на показ лоадера на старте
    setTimeout(() => {
      this.activeLoaderStart = false
    }, 2000)
  },
}
</script>

<style>
@font-face {
  font-family: 'Montserrat';
  src: url('./shared/assets/fonts/Montserrat-Regular.ttf') format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./shared/assets/fonts/Montserrat-Italic.ttf') format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./shared/assets/fonts/Montserrat-Medium.ttf') format("truetype");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./shared/assets/fonts/Montserrat-SemiBold.ttf') format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Montserrat';
  src: url('./shared/assets/fonts/Montserrat-Bold.ttf') format("truetype");
  font-weight: 700;
  font-style: normal;
}

*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

a, label, input{
  -webkit-tap-highlight-color: transparent;
}

body{
  font-family: 'Montserrat';
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: none;
  overscroll-behavior: none;
  background-color: #F3F3F4;
}

.page {
  background-color: #F3F3F4;
  height: 100%;
  font-size: 14px;
  font-weight: 400;
  position: relative;
}

.wrap{
  width: 100%;
  max-width: 872px;
  margin: 0 auto;
}
@media screen and (min-width: 900px){
  .container{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 64px;
    padding-bottom: 90px;
  }
}

.hr{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9A9B9A;
  position: relative;
  margin: 24px 0;
  width: 100%;
  text-align: center;
}

.hr:before,
.hr:after{
  content: '';
  height: 1px;
  width: 40%;
  background-color: #D9D9D9;

  position: absolute;
  top: 50%;
}

.hr--full:after,
.hr--full:before{
  width: 50%;
}

.hr:before{
  left: 0;
  /*transform: translateX(-100%);*/
}
.hr:after{
  right: 0;
  /*transform: translateX(100%);*/
}


/* Ландшафтная ориентация шаблона*/
@media screen and (orientation:landscape) {
  body{
    max-height: none;
    overflow: auto;
    padding-bottom: 0;
  }
}

.highlight{
  font-weight: 600;
}

.skeleton{
  animation: fade 1s linear infinite alternate;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}



@keyframes up-me {
  0% {
    top: 16px;
  }
  50%{
    top: 8px;
  }
  100% {
    top: 16px;
  }
}

@keyframes fade {
  0% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

.slide-enter-active,
.slide-leave-active {
  transition: 1s;
}

.slide-enter-from{
  left: -100%;
  transform: translateX(0);
}
.slide-leave-to {
  left: 0;
  transform: translateX(-100%);
}

.slide-fade-enter-active {
  transition: all .3s linear;
}

.slide-fade-leave-active {
  transition: all .3s linear;
}

.slide-fade-enter-from,
.slide-fade-leave-to {
  transform: translateX(-100%);
  opacity: 0;
}

</style>