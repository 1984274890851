<template>
  <div class="content__headline">
    <base-h3 content="Уведомления"></base-h3>
  </div>
  <list-information></list-information>
</template>
<script>
import BaseH3 from "@/shared/ui/BaseH3";
import ListInformation from "@/entities/ListInformation";
export default {
  name: "SectionInformation",
  components: {ListInformation, BaseH3}
}
</script>

<style scoped>
.content__headline{
  margin-bottom: 16px;
}
</style>