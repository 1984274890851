<template>
  <div class="content__footer">
    <div>
      <span class="white-space">Остались вопросы?</span>
      <base-link content="8 800 77 000 40" link="tel:88007700040"></base-link>
    </div>
    <div class="footer__logo"></div>
  </div>
  <div class="container-list-document">
    <div class="copyright">© Группа компаний «Байт Транзит», 2023</div>
    <a class="copyright link-document" @click="handleClickPolicy">Политика обработки персональных данных</a>
  </div>
</template>

<script>
import BaseLink from "@/shared/ui/BaseLink";
import {mapMutations} from "vuex";
export default {
  name: "SectionFooter",
  components: {BaseLink},
  methods:{
    ...mapMutations(['updatePathModalPolicy']),
    handleClickPolicy: function (){
      this.updatePathModalPolicy(true)
    }
  }
}
</script>

<style scoped>
.white-space{
  white-space: nowrap;
}

.link-document{
  text-decoration: underline;
  cursor: pointer;
}

.copyright{
  display: inline-block;
  position: relative;
  font-size: 10px;
  color: #333;
  text-align: center;
}

.footer__logo{
  width: 100%;
  height: 48px;
  background: url("../shared/assets/image/logo-minimal.svg") no-repeat left center / contain;
}

.content__footer{
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  align-items: flex-end;
  text-align: right;
}

.container-list-document{
  text-align: center;
  margin-top: 24px;
}

</style>