<template>
  <template v-if="skeleton">
    <p style="margin-bottom: 8px; color: #888888;">Отслеживаем ваше отправление...</p>
    <p style="margin-bottom: 24px; color: #888888;">Это может занять пару минуту, но обычно быстрее.</p>
  </template>
  <ul v-if="skeleton" class="skeleton tracking-info">
    <li class="tracking-info__item"></li>
    <li class="tracking-info__item"></li>
  </ul>
  <ul v-else class="tracking-info">
    <li class="tracking-info__item" v-if="notFoundMass" style="color: #888888;">Параметры отправления не найдены</li>
    <li class="tracking-info__item" v-else>
      <p>
        <span class="tracking-info__label">Вес:</span>
        {{ formattedMass }} г
      </p>
    </li>
  </ul>
</template>

<script>
export default {
  name: "AboutTracking",
  props:{
    skeleton: {
      type: Boolean
    },
    data:{
      type: Object
    },
  },
  computed:{
    formattedMass(){
      if(this.data != null){
        return Number(this.data.mass).toLocaleString()
      } else {
        return null
      }
    },
    notFoundMass(){
        return this.data != null && this.data.mass != null
      }
  }
}
</script>

<style scoped>
.skeleton .tracking-info__item{
  width: 150px;
  height: 17px;
  border-radius: 2px;

  background: linear-gradient(90deg, #CFCFCF 0%, #F5F5F5 53.13%, #CECDCD 99.48%);
  background-size: 200%;
}

.tracking-info__item{
  margin: 4px 0;
}

.tracking-info{
  list-style: none;
  margin-bottom: 32px;
}
.tracking-info__label{
  font-weight: 600;
  color: #9A9B9A;
  text-transform: uppercase;
  font-size: 11px;
}
</style>