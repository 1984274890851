
export const windowListenerResize = () => {
    window.addEventListener('resize', handleWindowResize);
}
export const handleWindowResize = () => {
    return document.documentElement.clientWidth
}

export const windowListenerOrientation = () => {
    window.addEventListener("orientationchange",  handleWindowOrientation);
}
export const handleWindowOrientation = () => {
    return document.documentElement.clientWidth
}