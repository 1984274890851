<template>
  <div v-if="loadingResponse" class="container-card-tariff">
    <card-tariff :skeleton="true"></card-tariff>
  </div>
  <template v-else>
<!--    <div class="container-card-tariff" v-for="(tariff, index) in listTariff" :key="index">-->
    <div class="container-card-tariff">
      <card-tariff
          :data-tariff="listTariff.response.result"
          :skeleton="false"
          @selectTariff="handleSelectTariff"
        ></card-tariff>
    </div>
  </template>
</template>
<script>
import CardTariff from "@/entities/tariff/CardTariff";
import {mapMutations} from "vuex";
export default {
  name: "ListTariff",
  components: {CardTariff},
  props:{
    loadingResponse:{
      type: Boolean
    },
    listTariff: {
      type: Object
    }
  },
  methods:{
    ...mapMutations(['updatePathOrder']),
    handleSelectTariff: function (){
      this.updatePathOrder(true)
    }
  }
}
</script>

<style scoped>
.container-card-tariff{
  margin: 16px 0;
}
.container-card-tariff:last-child{
  margin-bottom: 0;
}
</style>