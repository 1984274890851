export const validRussianLetter = value =>{
    return (/^[а-яА-Я- ]+$/.test(value))
}
export const validSymbolsNumber = value =>{
    return value.replace(/[^\d,.]*/g, '')
        .replace(/([,.])[,.]+/g, '$1')
        .replace(/^[^\d]*(\d+([.,]\d{0,5})?).*$/g, '$1')
        .replace(/,/, '.')
}
export const validMaxWeight = value =>{
    return (value <= 1500) ? value : null
}
export const validMaxWeightForFastDelivery = value =>{
    return (value <= 30) ? value : null
}
export const validMinWeight = value =>{
    return (Number(value) !== 0) ? value : null
}
export const validMinEstimated = value =>{
    return (Number(value) !== 0) ? value : null
}
export const validMaxEstimated = value =>{
    return (value < 999999999) ? value : null
}
export const validMinSymbolsForSelectSearch = (str, counter = 2) =>{
    return str.length > counter ? str : null
}

