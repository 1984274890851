const PR_CALCULATE_POINTS_URL = process.env.VUE_APP_PR_BASEURL + process.env.VUE_APP_PR_CALCULATE_POINTS_URL;

export const postCalculate = async obj => {
    let url = new URL(PR_CALCULATE_POINTS_URL);
    // url = 'http://10.54.10.54:8000/get-408';
    // url = 'http://192.168.4.223:8000/calculate';
    const request = {
        method: 'POST',
        body: JSON.stringify(obj),
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            'accept': 'application/json',
        }
    };
    const fetchPost = await fetch(url, request);
    const status = fetchPost.status;
    const result = await fetchPost.json()

    return {status, result}
}