<template>
  <h3 class="h3"> {{ content }} </h3>
</template>

<script>
export default {
  name: "BaseH3",
  props:{
    content: String,
  }
}
</script>

<style scoped>
.h3{
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 130%;
  color: #5B5B5B;
}
</style>