<template>
  <div class="page preview-order">

    <base-button-back @eventBack="handleEventBack"></base-button-back>

    <div class="wrap">
      <div class="container">
        <div class="content">
          <div class="container-h1">
            <base-h1 content="Пункт сдачи груза"></base-h1>
          </div>
          <card-address
              :address="location.address_ops"
              :phone="location.phone"
          ></card-address>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButtonBack from "@/shared/ui/BaseButtonBack";
import BaseH1 from "@/shared/ui/BaseH1";
import CardAddress from "@/entities/point/CardAddress";
import {mapGetters, mapMutations} from "vuex";
export default {
  name: "PreviewOrder",
  components: {CardAddress, BaseH1, BaseButtonBack},
  computed:{
    ...mapGetters(['getDepartureLocation']),
    location(){
      return this.getDepartureLocation
    }
  },
  methods:{
    ...mapMutations(['updatePathOrder']),
    handleEventBack: function (){
      this.updatePathOrder(false)
    }
  }
}
</script>

<style scoped>
.container-h1{
  margin-bottom: 16px;
}

.preview-order{
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  min-height: 100vh;

  z-index: 12;
}

.preview-order .container{
  display: block;
}

.container-card-address{
  margin: 16px 0;
}

.container-h1{
  margin-bottom: 16px;
}

.content{
  max-width: 418px;
  padding: 124px 32px 32px;
}

@media screen and (min-width: 900px){
  .content{
    padding: 0;
    padding-top: 64px;
  }
}
@media screen and (min-width: 1100px){
  .content{
    padding: 0;
  }
}
</style>