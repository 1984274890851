// Перемещает в начало массива значения, котороые имеют совпадения по нужном key объекта
export const sortByKey = (list, key, searchRequest) => {
    let filter;
    // console.log(list, key, searchRequest)

    if(searchRequest !== ''){
        const regexp = new RegExp(searchRequest, 'gi');
        filter = list.filter(item => item[key].match(regexp)); // возвращаем list по полю place
        filter.forEach(item => {
            list.splice(list.indexOf(item), 1)
            list.unshift(item)
        });
    }
    return list
}