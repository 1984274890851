<template>
    <base-warn
        :type="readyFields ? 'success' : 'attention'"
        :message="readyFields ? 'Готово к расчету' : 'Заполнены не все поля'"
    ></base-warn>
</template>

<script>
import BaseWarn from "@/shared/ui/BaseWarn";
export default {
  name: "WarnFields",
  components: {BaseWarn},
  props:{
    state:{
      type: String
    }
  },
  computed:{
    readyFields(){
      return this.state === 'ready'
    }
  }
}
</script>