// Игнор английской раскладки
export const ignoreKeyboardLayout = (string, reverse) =>{
    let replace;
    let replacer = {
        "q": "й", "w": "ц", "e": "у", "r": "к", "t": "е", "y": "н", "u": "г",
        "i": "ш", "o": "щ", "p": "з", "[": "х", "]": "ъ", "a": "ф", "s": "ы",
        "d": "в", "f": "а", "g": "п", "h": "р", "j": "о", "k": "л", "l": "д",
        ";": "ж", "'": "э", "z": "я", "x": "ч", "c": "с", "v": "м", "b": "и",
        "n": "т", "m": "ь", ",": "б", ".": "ю", "/": ".",
    };
    reverse && Object.keys(replacer).forEach(key => {
        let v = replacer[key]
        delete (replacer[key])
        replacer[v] = key
    })
    for (let i = 0; i < string.length; i++) {
        if (replacer[string[i].toLowerCase()] != undefined) {
            if (string[i] == string[i].toLowerCase()) {
                replace = replacer[string[i].toLowerCase()];
            } else if (string[i] == string[i].toUpperCase()) {
                replace = replacer[string[i].toLowerCase()].toUpperCase();
            }
            string = string.replace(string[i], replace);
        }
    }
    return string;
}