<template>
  <div class="form-frame">
    <section-form-tracking></section-form-tracking>
    <div class="hr">или</div>
    <section-form-tariff></section-form-tariff>
  </div>
</template>

<script>
import SectionFormTracking from "@/widgets/tracking/SectionFormTracking";
import SectionFormTariff from "@/widgets/tariff/SectionFormTariff";
export default {
  name: "FrameForms",
  components: {
    SectionFormTariff,
    SectionFormTracking
  }
}
</script>

<style scoped>
.form-frame{
  padding: 0 32px;
  padding-top: 32px;
}
@media screen and (min-width: 900px) {
  .form-frame {
    width: 100%;
    max-width: 418px;
    padding: 0;
    padding-top: 48px;
  }
}
</style>