<!-- все хорошо: warn--good | icon--good -->
<!-- внимание: warn--attention | icon--attention -->
<!-- ошибка: warn--error | icon--error -->
<template>
  <div class="warn" :class="handleType">
    <div class="icon"></div>
    <div class="content">{{ message }}</div>
  </div>
</template>

<script>
export default {
  name: "BaseWarn",
  props:{
    type: String,
    message: String,
  },
  computed:{
    handleType(){
      if(this.type === 'success'){
        return 'warn--good'
      } else if(this.type === 'attention'){
        return 'warn--attention'
      } else if(this.type === 'error'){
        return 'warn--error'
      } else {
        return ''
      }
    },
  },
}
</script>

<style scoped>
.warn{
  border: 1px solid;
  border-radius: 10px;
  padding: 16px 24px;
  padding-left: 48px;
  position: relative;
}

.warn--good{
  background-color: #F0FFF5;
  border-color: #11A640;
}
.warn--attention{
  background-color: #FFFFEF;
  border-color: #ECB86A;
}
.warn--error{
  background-color: #FFEFEF;
  border-color: #E41F2C;
}

.icon{
  width: 20px;
  height: 20px;

  background: no-repeat center / 100%;
  position: absolute;
  top: 16px;
  left: 16px;
}

.warn--good .icon{
  background-image: url("../assets/image/ic_warn-good.svg");
}
.warn--attention .icon{
  background-image: url("../assets/image/ic_warn-attention.svg");
}
.warn--error .icon{
  background-image: url("../assets/image/ic_warn-error.svg");
}

.content{
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #5B5B5B;
}


</style>