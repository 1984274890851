<template>
  <div class="page modal-policy">
    <base-button-back @eventBack="handleEventBack"></base-button-back>
    <div class="wrap">
      <div class="container document">
        <div class="content document__content">
          <div class="document__container-h1">
            <base-h1 content='Политика общества с ограниченной ответственностью "Байт-Транзит-Континент"
            в отношении обработки персональных данных'></base-h1>
          </div>
          <div class="document__container-h3">
            <base-h3 content="1. Общие положения"></base-h3>
          </div>
          <p>1.1. Настоящая Политика общества с ограниченной ответственностью "Байт-Транзит-Континент" в отношении обработки персональных данных (далее - Политика) разработана во исполнение требований п. 2 ч. 1 ст. 18.1 Федерального закона от 27.07.2006 N 152-ФЗ "О персональных данных" (далее - Закон о персональных данных).</p>
          <p>1.2.  Настоящая Политика принята ООО «БТК» (далее - «Компания»), осуществляющей обработку персональных данных, самостоятельно и добровольно размещенных субъектами персональных данных (с согласия таких субъектов персональных данных.</p>
          <p>Юридический адрес компании: г. Новосибирск, ул. Фрунзе, 18/1, офис 18.</p>
          <p>Фактический и почтовый адрес компании: 630001, г. Новосибирск, ул. Владимировская, 26/1, офис 305.</p>
          <p>1.4. Во исполнение требований ч. 2 ст. 18.1 Закона о персональных данных настоящая Политика публикуется в свободном доступе в том числе в информационно-телекоммуникационной сети Интернет на сайте Компании по адресу: <base-link :new-window="true" content="www.sibtrans.ru" href="https://www.sibtrans.ru"></base-link>.</p>
          <p>1.5. Используемые в настоящем документе понятия толкуются согласно их определению в Федеральном законе «О персональных данных».</p>
          <div class="document__container-h3">
            <base-h3 content="2.  Термины и определения"></base-h3>
          </div>
          <p>В настоящей Политике используются следующие термины и определения:</p>
          <p>2.1. «Персональные данные» — любая информация, относящаяся прямо или косвенно к определенному физическому лицу (Субъекту персональных данных). В частности, но не ограничиваясь, под «персональными данными» понимается следующая информация о субъекте персональных данных (при условии предоставления указанной информации): ФИО, пол, номер телефона, адрес электронной почты, паспортные данные (серия и номер паспорта, код подразделения, дата выдачи, наименование государственного органа, выдавшего паспорт), ИНН, адрес регистрации, адрес места нахождения, данные о номере банковского счета, реквизитах банковской карты, код груза.</p>
          <p>2.2. «Обработка персональных данных» — любое действие (операция) или совокупность действий (операций), совершаемых с использованием средств автоматизации или без использования таких средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных данных.</p>
          <p>2.3. «Конфиденциальность персональных данных» — обязательное для соблюдения Компанией или иным получившим доступ к персональным данным лицом требование не допускать их распространения без согласия субъекта персональных данных или наличия иного законного основания.</p>
          <p>2.4. «Субъект персональных данных» — физическое лицо, к которому относятся соответствующие персональные данные.</p>
          <p>2.5. «Сайт Компании», «Сайт» - сайт Компании, размещенный в информационно-телекоммуникационной сети Интернет по адресу: <base-link :new-window="true" content="www.sibtrans.ru" href="https://www.sibtrans.ru">www.sibtrans.ru</base-link>.</p>
          <div class="document__container-h3">
            <base-h3 content="3.  Правила предоставления персональных данных"></base-h3>
          </div>
          <p>3.1. Предоставление Субъектами персональных данных Компании своих персональных данных, в том числе посредством использования Сайта означает безусловное согласие с настоящей Политикой и условиями обработки персональных данных. В случае несогласия с условиями Политики Пользователь должен прекратить предоставление персональных данных.</p>
          <p>3.2.  Компания собирает, использует и охраняет персональные данные, которые Субъект персональных данных предоставляет Компании в том числе путем использования сайта с любого устройства и при коммуникации с Компанией в любой форме, в соответствии с данной Политикой.</p>
          <p>3.3. Компания не проверяет достоверность персональных данных, предоставляемых Субъектами персональных данных.</p>
          <div class="document__container-h3">
            <base-h3 content="4. Цели обработки персональных данных "></base-h3>
          </div>
          <p>Обработка персональных данных Субъектов персональных данных осуществляется Компанией в следующих целях:</p>
          <p>4.1. Осуществление деятельности по администрированию Сайта и обеспечению его функциональности, в том числе обеспечение возможности отправки резюме на открытые в Компании вакансии, обеспечение возможности информирования пользователей Сайта об услугах и новостях Компании, обеспечение возможности пользователям Сайта обратиться в Компанию за получением интересующей их информации и т.п.;</p>
          <p>4.2.  Ведение статистического и аналитического учета; </p>
          <p>4.3. Заключение договоров с Субъектами персональных данных, в том числе договоров на оказание услуг по транспортировке груза оказание пользователям дополнительных услуг (если это предусмотрено), и т.п.;</p>
          <p>4.4. Направление Субъектам персональных данных сообщений, касающихся вопросов функционирования и использования сервисов Сайта, вопросов заключения и исполнения договоров на оказание услуг по транспортировке груза, оказание пользователям иных дополнительных услуг, предоставления Субъектам персональных данных эффективной клиентской и технической поддержки;</p>
          <p>4.5.  В иных целях, не противоречащих действующему законодательству Российской Федерации.</p>
          <div class="document__container-h3">
            <base-h3 content="5.  Принципы обработки персональных данных"></base-h3>
          </div>
          <p>Обработка персональных данных в Компании осуществляется на основе следующих принципов:</p>
          <p>5.1. Компания соблюдает принципы и условия, предусмотренные действующим законодательством Российской Федерации о персональных данных;</p>
          <p>5.2. Для хранения персональных данных используются базы данных, находящиеся на территории Российской Федерации;</p>
          <p>5.3. Обработка персональных данных ограничивается обработкой персональных данных для достижения конкретных, заранее определенных и законных целей;</p>
          <p>5.4. Компания осуществляет обработку только тех персональных данных, которые отвечают целям их обработки;</p>
          <p>5.5. Соответствия содержания и объема обрабатываемых персональных данных заявленным целям обработки;</p>
          <p>5.6. Не допускается обработки персональных данных, избыточных по отношению к заявленным целям их обработки.</p>
          <div class="document__container-h3">
            <base-h3 content="6. Условия обработки персональных данных"></base-h3>
          </div>
          <p>6.1. Обработка персональных данных в Компании осуществляется с соблюдением условий, предусмотренных действующим законодательством Российской Федерации о персональных данных.</p>
          <div class="document__container-h3">
            <base-h3 content="7. Права Субъекта персональных данных"></base-h3>
          </div>
          <p>7.1. Субъект персональных данных, принимая решение о предоставлении своих персональных данных, действует свободно, своей волей и в своем интересе, безусловно выражая свое согласие.</p>
          <p>7.2. Субъект персональных данных имеет право на получение у Компании информации, касающейся обработки его персональных данных, если такое право не ограничено в соответствии с законодательством РФ.</p>
          <p>7.3. Субъект персональных данных вправе требовать от Компании уточнения его персональных данных, их блокирования или уничтожения в случае, если персональные данные являются неполными, устаревшими, неточными, незаконно полученными или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные законом меры по защите своих прав.</p>
          <p>7.4. Субъект персональных данных вправе направить Компании соответствующие письменные запросы, отвечающих положениям Закон о персональных данных на уточнение и изменение своих персональных данных самостоятельно через личный кабинет на Сайте или путем обращения в Компанию.</p>
          <div class="document__container-h3">
            <base-h3 content="8. Обеспечение безопасности при обработке персональных данных"></base-h3>
          </div>
          <p>8.1. Безопасность персональных данных, обработка которых осуществляется Компанией, обеспечивается посредством применения правовых, организационных, технических и программных мер, необходимых и достаточных для соблюдения требований действующего законодательства Российской Федерации о персональных данных.</p>
          <p>8.2. Перечень конкретных мер, принимаемых Компанией в целях обеспечения безопасности персональных данных, определяется Компанией самостоятельно и может включать в себя, в частности: </p>
          <p>8.2.1. ограничение состава сотрудников Компании, имеющих доступ к персональным данным;</p>
          <p>8.2.2. назначение должностного лица, ответственного за организацию обработки персональных данных в Компании;</p>
          <p>8.2.3. ознакомление сотрудников Компании с требованиями действующего законодательства Российской Федерации о персональных данных и положениями настоящей Политики;</p>
          <p>8.2.4. иные меры, соответствующие требованиям, предъявляемым действующим законодательством Российской Федерации к защите персональных данных.</p>
          <p>8.3. Компания не осуществляет передачу или раскрытие персональных данных, предоставленных Субъектом персональных данных третьим лицам, за исключением случаев, исполнения заключенных с Субъектом персональных данных договоров и иных случаев, предусмотренных законодательством Российской Федерации.</p>
          <p>8.4. Компания не несёт ответственность в случае утраты или разглашения персональных данных Пользователя, если они:</p>
          <p>8.4.1. стали публичным достоянием до их утраты или разглашения Компанией;</p>
          <p>8.4.2. были получены Компанией от третьей стороны до момента их передачи Компании Пользователем; </p>
          <p>8.4.3. были разглашены с согласия Пользователя.</p>
          <div class="document__container-h3">
            <base-h3 content="9. Заключительные положения"></base-h3>
          </div>
          <p>9.1. Политика подлежит изменению в случаях внесения соответствующих изменений или дополнений в действующее законодательство Российской Федерации о персональных данных, а также может быть изменена в любое время по усмотрению Компании.</p>
          <p>9.2. Действующая редакция Политики всегда доступна для просмотра неограниченным кругом лиц в том числе на Сайте по постоянному адресу: <base-link :new-window="true" content="www.sibtrans.ru" href="https://www.sibtrans.ru">www.sibtrans.ru</base-link>.</p>
          <p>9.3. Все отношения с участием Компании, касающиеся обработки и защиты персональных данных и не получившие непосредственного регулирования в настоящем документе, регулируются положениями действующего законодательства Российской Федерации о персональных данных.</p>
          <p>9.4. Субъект персональных данных вправе отозвать свое согласие на обработку его персональных данных в любой момент, используя форму уведомления, установленную Приложением № 1 к настоящей Политики, направив соответствующее письменное уведомление Компании в порядке, установленном Федеральным законом о персональных данных по почтовому адресу Компании, указанному в настоящей Политики. Субъект персональных данных вправе составить уведомления об отзыве согласие на обработку персональных в произвольной форме при условии соответствия его законодательствую о персональных данных.</p>
          <p>9.5. Принимая и соглашаясь с условиями настоящей Политики, Пользователь предоставляет разрешение Компании на отправку почтовой корреспонденции на указанный почтовый адрес, включая приветственные письма с указанием его персональных данных, почтовые вложения, персонализированные рекламные буклеты/проспекты, иную информацию, а также на отправку электронных писем, рекламных и/или информационных сообщений на указанный адрес электронной почты и/или номер мобильного телефона, включая информацию о проводимых в Компании рекламных акциях, сведениях об услугах и новостях компании, а также сообщения иного информационного характера.</p>

          <div class="container-download-doc">
            <base-link :new-window="true" content="Скачать отзыв согласия на обработку персональных данных" href="http://sibtrans.ru/upload/politic-conf-form.pdf"></base-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButtonBack from "@/shared/ui/BaseButtonBack";
import {mapMutations} from "vuex";
import BaseH1 from "@/shared/ui/BaseH1";
import BaseH3 from "@/shared/ui/BaseH3";
import BaseLink from "@/shared/ui/BaseLink";
export default {
  name: "ModalPolicy",
  components: {BaseLink, BaseH3, BaseH1, BaseButtonBack},
  methods:{
    ...mapMutations(['updatePathModalPolicy']),
    handleEventBack: function (){
        this.updatePathModalPolicy(false)
    }
  }
}
</script>

<style scoped>

.modal-policy{
  padding: 124px 0 32px;
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;

  width: 100%;
  min-height: 100vh;

  z-index: 12;
}

.document{
  background: #FFFFFF;
  border-radius: 10px;
  padding: 32px;
  padding-bottom: 64px;
}

@media screen and (min-width: 900px){
  .modal-policy{
    padding: 0;
    padding-top: 64px;
    padding-bottom: 64px;
  }
}

.document__content p{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #333333;
  margin: 16px 0;
}

.document__container-h1{
  margin-bottom: 24px;
}

.document__container-h3{
  margin: 24px 0 16px;
}

.container-download-doc{
  margin-top: 48px;
}


@media screen and (min-width: 900px){
  .document{
    padding: 32px 48px;
    padding-bottom: 72px;
  }
}
</style>