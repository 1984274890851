<template>
  <div>
    <base-h1 class="base-h1--margin" content="Отслеживание груза"></base-h1>
    <form-tracking></form-tracking>
    <base-tooltip class='base-tooltip--margin' headline="Где уникальный идентификатор (УИН)">
      <p>Уникальный идентификатор перевозки указан в транспортной накладной.</p>
      <p>Найдите строку вида "УИН: XXXXXXXXXXXX".</p>
      <img class="img_doc" src="../../shared/assets/image/ill_doc.png" alt="document">
    </base-tooltip>
  </div>
</template>

<script>
import BaseH1 from "@/shared/ui/BaseH1";
import BaseTooltip from "@/shared/ui/BaseTooltip";
import FormTracking from "@/features/search-tracking/FormTracking";
export default {
  name: "SectionFormTracking",
  components: {FormTracking, BaseTooltip, BaseH1},
}
</script>

<style scoped>
.base-h1--margin{
  margin-bottom: 16px;
}
.base-tooltip--margin{
  margin-top: 12px;
}
.img_doc{
  width: 167.58px;
  height: 157px;
  margin-top: 8px;
}
</style>