const PR_TRACKING_URL = process.env.VUE_APP_PR_BASEURL + process.env.VUE_APP_PR_TRACKING_URL;

export const postTracking = async str => {
    let url = new URL(PR_TRACKING_URL) + `?uid=` + str;
    // console.log(str)
    // console.log(url)
    // url = 'http://10.54.10.54:8000/get-408';
    const request = {
        method: 'POST',
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            'accept': 'application/json',
        }
    };

    // console.log(request)
    const fetchPost = await fetch(url, request);
    const status = fetchPost.status;
    const result = await fetchPost.json()

    return {status, result}


    // if(status === 200){
    //     return result;
    // } else{
    //     const error = {
    //         error: status,
    //         message: ''
    //     }
    //     if(status === 400){
    //         error.message = 'Не удалось отследить статус. Попробуйте позже.'
    //     } else if(status === 404){
    //         error.message = 'Не найдено'
    //     } else if(status === 408){
    //         error.message = 'Превышено время ожидания. Попробуйте позже.'
    //     } else if(status === 422){
    //         error.message = 'Неизвестная ошибка'
    //     }else if(status === 500 || status === 502 || status === 504){
    //         error.message = 'Ошибка сервера. Попробуйте позже.'
    //     }
    //     return error
    // }
}