<template>
  <ul v-if="visibleSkeleton" class="list">
    <li class="item skeleton" v-for="(skeleton, index) in skeletons" :key="index">
      <div class="title"></div>
      <div class="description"></div>
    </li>
  </ul>

  <template v-else>

    <!-- Дефолтный список -->
    <template v-if="defaultList">
      <div class="underfunded">По запросу ничего не найдено</div>
      <div class="container-default-list__title">
        <base-h3 content="Доступные города для отправки"></base-h3>
      </div>
    </template>

    <!-- Список по поиску -->
    <ul class="list">
      <li
          v-for="point in points"
          :key='point.postcode'
      >
        <p class="point__headline">
          {{point.place_type}}
          <span class="point__title" v-html="highlight(point.place, search)"></span>
          <span class="point__description" v-show="point.location || point.region">
            {{ point.location }} {{ point.region }}
          </span>
        </p>

        <ul>
          <li
              v-for="location in point.locations"
              :key='location.id'
              class="item"
              @mousedown.self="clickOnLocation(location, point)"
          >
              <p class="title"> {{ location.address_ops }} </p>
              <p class="description"> Индекс: {{ location.ops }} </p>
          </li>
        </ul>
      </li>
    </ul>
  </template>
</template>

<script>
import BaseH3 from "@/shared/ui/BaseH3";
import { highlightText } from "@/shared/libs/highlightText"

export default {
  name: "ListPointsDeparture",
  components: {BaseH3},
  props: {
    points:{
      type: Object
    },
    search: String,
    visibleSkeleton: Boolean,
    defaultList: {
      type: Boolean,
      require: false
    }
  },
  emits: ["selectPoint", 'selectLocation'],
  data(){
    return{
      skeletons: ['1', '2', '3'],
      point: null
    }
  },
  methods:{
    highlight(text, search){
      return highlightText(text, search)
    },
    clickOnLocation(location, point){
      this.$emit("selectPoint", point)
      this.$emit("selectLocation", location)
    },
  },
}
</script>

<style scoped>

.point__headline{
  background-color: #FBFAF9;
  font-size: 14px;
  padding: 10px 16px 12px;
}

.point__description{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9F9CB6;
}

.title{
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 4px;
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
  /*white-space: nowrap;*/
}

.title .description{
  padding-left: 3px;
  display: inline;
  /*white-space: nowrap;*/
  overflow: hidden;
  text-overflow: ellipsis;
}

.description{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #9A9B9A;
}

.description span{
  padding-right: 3px;
}

.description span:last-child{
  padding-right: 0;
}

.underfunded{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9A9B9A;
  padding: 8px 16px;
}

.container-default-list__title{
  padding: 16px 16px 8px;
  position: relative;
}

.item{
  overflow: hidden;
  list-style: none;
  padding: 10px 16px;
  position: relative;
  cursor: pointer;
  transition: 200ms;
  padding-left: 40px;
  background: url("../../../shared/assets/image/ic_location.svg") no-repeat 16px 16px / 13px;
}

.item > *{
  pointer-events: none;
}

.container-default-list__title:after,
.item:after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;
  height: 1px;
  background-color: #F3F3F4;
}

.item:last-child:after{
  display: none;
}

.item:focus,
.item:hover{
  background-color: #F3F3F4;
}

.skeleton .title{
  width: 100px;
  height: 18px;
  border-radius: 2px;
  background: linear-gradient(90deg, #CFCFCF 0%, #F5F5F5 53.13%, #CECDCD 99.48%);
  background-size: 200%;
}

.skeleton .description{
  width: 150px;
  height: 12px;
  background: linear-gradient(90deg, #E1E1E1 0%, #E9E8E8 100%);
  opacity: 50%;
  margin: 4px 0;
}
</style>