<template>
    <div class="page modal-info">
      <base-button-back @eventBack="handleEventBack"></base-button-back>
      <div class="wrap">
        <div class="container">
          <div class="content">
            <div class="container-h1">
              <base-h1 content="Информация"></base-h1>
            </div>
            <list-information></list-information>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import BaseButtonBack from "@/shared/ui/BaseButtonBack";
import BaseH1 from "@/shared/ui/BaseH1";
import ListInformation from "@/entities/ListInformation";
import {mapMutations} from "vuex";
export default {
  name: "ModalHelp",
  components: {ListInformation, BaseH1, BaseButtonBack},
  methods:{
    ...mapMutations(['updatePathModalHelp']),
    handleEventBack: function (){
      this.updatePathModalHelp(false)
    }
  },
}
</script>

<style scoped>
.container-h1{
  margin-bottom: 16px;
}

.modal-info{
  position: fixed;
  top: 0;
  left: 0;

  width: 100%;
  min-height: 100vh;

  z-index: 12;
}

.content{
  max-width: 500px;
  padding: 124px 32px 32px;
}

@media screen and (min-width: 900px){
  .content{
    padding: 0;
    padding-top: 64px;
  }
}
@media screen and (min-width: 1100px){
  .content{
    padding: 0;
  }
}
</style>