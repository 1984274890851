<template>
  <h1 class="h1">{{ content }}</h1>
</template>

<script>
export default {
  name: "BaseH1",
  props:{
    content: String
  }
}
</script>

<style scoped>
.h1{
  font-weight: 700;
  font-size: 18px;
  line-height: 130%;
  color: #333333;
}
</style>