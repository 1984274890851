<template>
  <div class="container-warn">
    <base-warn
        :type="formErrorType"
        :message="formErrorMessage"
    ></base-warn>
  </div>
</template>

<script>
import BaseWarn from "@/shared/ui/BaseWarn";
import {mapGetters} from "vuex";
export default {
  name: "ListError",
  components: {BaseWarn},
  computed:{
    ...mapGetters(['getError']),
    formErrorType(){
      if(this.getError != null){
        return this.getError.warn
      } else {
        return null
      }
    },
    formErrorMessage(){
      if(this.getError != null){
        return this.getError.message.response
      } else {
        return null
      }
    },
  }
}
</script>

<style scoped>
.container-warn{
  margin: 16px 0;
}
</style>