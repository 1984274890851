const PR_DEPARTURE_POINTS_URL = process.env.VUE_APP_PR_BASEURL + process.env.VUE_APP_PR_DEPARTURE_POINTS_URL;

export const getDeparture = (all = 'true', search) => {
    let url = new URL(PR_DEPARTURE_POINTS_URL);
    // url = 'http://192.168.4.223:8000/departure-ops';
    Object.entries({
        all,
        search
    }).forEach(([key, value]) => {
        if(value != null){
            url.searchParams.append(key, value)
        }
    });
    return fetch(url);
}

const PR_DESTINATION_POINTS_URL = process.env.VUE_APP_PR_BASEURL + process.env.VUE_APP_PR_DESTINATION_POINTS_URL;

export const getDestination = search => {
    const url = new URL(PR_DESTINATION_POINTS_URL);
    Object.entries({
        search
    }).forEach(([key, value]) => {
        if(value != null){
            url.searchParams.append(key, value)
        }
    });
    return fetch(url);
}