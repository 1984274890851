<template>
  <div class="container-h1">
    <base-h1 content="Калькулятор стоимости и сроков"></base-h1>
  </div>
  <form-tariff></form-tariff>
</template>

<script>
import FormTariff from "@/features/calculate-tariff/FormTariff";
import BaseH1 from "@/shared/ui/BaseH1";
export default {
  name: "SectionFormTariff",
  components: {BaseH1, FormTariff}
}
</script>

<style scoped>
.container-h1{
  margin-bottom: 16px;
}

</style>