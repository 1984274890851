<template>
  <form>
    <div class="form__section">
      <div class="container-input">
        <select-departure @selectPoint="handleSelectedDeparture" @selectLocation="handleSelectedDepartureLocation"></select-departure>
      </div>
      <div class="container-input">
        <selector-destination @selectPoint="handleSelectedDestination"></selector-destination>
      </div>
      <div class="container-input">
        <base-input
            :attributes="{ label: 'Ценность:', after: 'Р', type: 'number', model: 'estimated'}"
            :value="setEstimated"
            :invalid="estimatedError"
            @getInputValue="handleValueEstimated"
        ></base-input>
      </div>

      <div class="container-input">
        <base-input
            :attributes="{ label: 'Вес:', after: 'кг', type: 'number', model: 'weight'}"
            :value="setWeight"
            :invalid="weightError"
            @getInputValue="handleValueWeight"
        ></base-input>
      </div>
    </div>

    <div class="form__button">
      <base-button
          :content="refreshForm ? 'Повторить' : 'Рассчитать'"
          :refresh="refreshForm"
          :disabled="lockButtonCalculate"
          :isLoading="loadingResponse"
          color="black"
          @click.prevent="getTariffs"
      ></base-button>
    </div>
  </form>
</template>

<script>
import SelectDeparture from "@/entities/point/departure/SelectorDeparture";
import SelectorDestination from "@/entities/point/destination/SelectorDestination";
import BaseInput from "@/shared/ui/BaseInput";
import BaseButton from "@/shared/ui/BaseButton";

import {validMaxWeight, validMinWeight, validMinEstimated, validMaxEstimated} from "@/shared/libs/validation";
import {getListTariffs} from "@/features/calculate-tariff/postFormTariff";

import {scrollToFrame} from "@/shared/libs/scrollTo";
import {mapGetters, mapMutations} from "vuex";

export default {
  name: "FormTariff",
  components: {BaseButton, BaseInput, SelectorDestination, SelectDeparture},
  data(){
    return{
      estimatedError: null,
      weightError: null,

      departurePostcode: null,
      departure: null,
      departureLocation: null,
      departureId: null,

      destinationPostcode: null,
      destination: null,

      estimated: null,
      setEstimated: null, // для перебивки значения, пока используется только для local storage
      weight: null,
      setWeight: null, // для перебивки значения, пока используется только для local storage

      lockButtonCalculate: true, // статус кнопки "Рассчитать"
      refreshForm: false, // статус кнопки "Повоторить"
    }
  },
  computed:{
    ...mapGetters(['stateLoadingResponseTariff']),
    loadingResponse(){
      return this.stateLoadingResponseTariff
    }
  },
  beforeMount() {
    const savedData = localStorage.getItem('storageCalculate')
    if(savedData != null){
      // есть сохраненные данные
      const res = JSON.parse(savedData);
      this.setEstimated = this.estimated = res.estimated.toString()
      this.setWeight = this.weight = res.weight.toString()
    }
  },
  watch:{
    departure(){
      this.handleFullnessFormTariff()
    },
    destination(){
      this.handleFullnessFormTariff()
    },
    estimated(){
      this.handleFullnessFormTariff()
    },
    weight(){
      this.handleFullnessFormTariff()
    },
  },
  methods:{
    ...mapMutations([
      'updateDestination',
      'updateDeparture',
      'updateEstimated',
      'updateWeight',
      'updateError',
      'updateListTariff',
      'updateParamsTariff',
      'waitingResponseTariff',
      'updatePathCalculate',
      'updateStateFieldsForCalculate',
      'updateStatePostedParamsTariff',
      'updatePathError',
      'updatePathStart',
      'updateDepartureLocation'
    ]),

    // Обрабатывает выбраную локацию в ПО
    handleSelectedDepartureLocation: function (locationDeparture){
      if(locationDeparture != null){
        this.departureId = locationDeparture.id != null ? locationDeparture.id.toString() : null
        this.updateDepartureLocation(locationDeparture)
      } else {
        this.departureId = null
        this.updateDepartureLocation(null)
      }
      this.departureLocation = locationDeparture
    },

    // Обрабатывает выбраный ПО
    handleSelectedDeparture: function (pointDeparture){
      if(pointDeparture != null){
        this.departurePostcode = Number(pointDeparture.postcode)
        this.updateDeparture(pointDeparture)
      } else{
        // пришел null (город не выбран)
        this.departurePostcode = null
        this.updateDeparture(null)
      }
      this.departure = pointDeparture
    },

    // Обрабатывает выбраный ПН
    handleSelectedDestination: function (pointDestination){
      if(pointDestination != null){
        this.destinationPostcode = Number(pointDestination.postcode)
        this.updateDestination(pointDestination)
      } else {
        // пришел null (город не выбран)
        this.destinationPostcode = null
        this.updateDestination(null)
      }
      this.destination = pointDestination
    },

    // Обрабатывает вес
    handleValueWeight: function (number){
      let weight;
      if(validMinWeight(number) === null){
        // n < 0
        this.weightError = {warn: 'error', message: 'Вес груза не должен быть равен 0'}
        weight = null
      }
      // else if(validMaxWeightForFastDelivery(number) === null && validMaxWeight(number) !== null){
      //   // 30 < n < 1500
      //   this.weightError = {warn: 'note', message: 'Скорая доставка недоступна при весе более 30 кг'}
      // }
      else if(validMaxWeight(number) === null){
        // n > 1500
        this.weightError = {warn: 'error', message: 'Максимальный вес груза не должен превышать 1500 кг'}
        weight = null
      } else {
        // валидно
        this.weightError = null
      }
      if(weight !== null){
        weight = Number(number)
      }
      this.updateWeight(weight)
      this.weight = weight
    },

    // Обрабатывает ценность
    handleValueEstimated: function (number){
      let estimated;
      if(validMinEstimated(number) === null){
        // n < 0
        this.estimatedError = {warn: 'error', message: 'Ценность груза не должна быть равна 0'}
        estimated = null
      } else if(validMaxEstimated(number) === null){
        // n > 999 999 999
        this.estimatedError = {warn: 'error', message: 'Максимальное значение ценности груза не должно превышать 999 999 999 Р'}
        estimated = null
      } else {
        // валидно
        this.estimatedError = null
      }
      if(estimated !== null){
        estimated = Number(number)
      }
      this.updateEstimated(estimated)
      this.estimated = estimated
    },

    // Получает список тарифов по калькулятору
    async getTariffs(){
      await this.waitingResponseTariff(true) // начало загрузки
      // TODO передавать количество скелетонов для тарифов
      this.updateStatePostedParamsTariff(true)// обнуление статуса заполненых полей
      this.updatePathError(false) // выключение окна ошибок
      await this.updatePathCalculate(true) // включение окна результатов
      scrollToFrame() // выдвигаем шторку
      const response = await getListTariffs(
          this.departurePostcode,
          this.departureId,
          this.destinationPostcode,
          this.estimated,
          this.weight,
          true) // fetch
      const list = response.listTariff // список тарифов [] / null
      const error = response.tariffError // ошибка {} / null
      console.log(error)
      this.refreshForm = error != null; // меняет статус кнопки "Повторить", взависимости от того, есть ли ошибка в результате
      this.updateListTariff(list) // записываем результат
      this.updateError(error) // записываем ошибку
      this.waitingResponseTariff(false) // загрузка закончена
    },

    // Обрабатывает заполнение формы
    handleFullnessFormTariff: function (){
      this.updateStatePostedParamsTariff(false) // обнуляет результат расчета
      if(this.departure != null && this.destination != null && this.estimated != null && this.weight != null){
        // форма заполнена полностью
        this.addLocalStorage() // записывает параметры расчета в local storage
        this.updateStateFieldsForCalculate('ready') // уведомление "Готово к расчету"
        this.lockButtonCalculate = false // разблокирует кнопку "Рассчитать"
      } else if (this.departure == null && this.destination == null && this.estimated == null && this.weight == null){
        // форма очищена
        this.lockButtonCalculate = true // блокирует кнопку "Рассчитать"
        this.cleanLocalStorage() // очистка local storage
        this.updatePathStart(true) // возвращает на стартовый фрейм
      } else {
        // форма заполнена не полностью
        this.refreshForm = false // отключает кнопку "Повторить"
        this.lockButtonCalculate = true // блокирует кнопку "Рассчитать"
        this.updatePathCalculate(true) // переключает на фрейм результатов
        this.updateStateFieldsForCalculate('empty') // уведомление "Не все поля заполнены"
      }
    },

    // Записывает в local storage
    addLocalStorage: function (){
      const params = {
        departure: this.departure,
        departure_location: this.departureLocation,
        destination: this.destination,
        estimated: this.estimated,
        weight: this.weight
      };
      localStorage.setItem('storageCalculate', JSON.stringify(params))
      localStorage.removeItem('storageTracking')
    },

    // Очищает local storage
    cleanLocalStorage: function (){
      localStorage.removeItem('storageCalculate')
    }
  },
}
</script>

<style scoped>
.container-input{
  margin: 8px 0;
}
.form__button{
  width: 100%;
}

.form__button button{
  width: 100%;
}
.form__section{
  margin: 24px 0;
}
.form__section:last-child{
  margin-bottom: 0;
}

@media screen and (min-width: 900px){
  .form__button{
    display: flex;
    justify-content: flex-end;
  }
  .form__button button{
    width: auto;
  }
}
</style>