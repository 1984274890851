<template>
  <ul>
    <li class="rules__item" v-for="(item, index) in listInfo" :key="index">
      <span class="rules__icon"></span>
      {{ item }}
    </li>
  </ul>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "ListInformation",
  computed:{
    ...mapGetters(['getListInformation']),
    listInfo(){
      return this.getListInformation
    }
  }
}
</script>

<style scoped>
.rules__item{
  list-style: none;
  margin-bottom: 16px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
}

.rules__icon{
  display: block;
  width: 12px;
  min-width: 12px;
  height: 12px;
  margin-right: 8px;
  margin-top: 4px;
  background: url("../shared/assets/image/ic_check.svg") no-repeat center / 100%;
}
</style>