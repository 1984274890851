<template>
  <list-error></list-error>
</template>

<script>
import ListError from "@/entities/ListError";
export default {
  name: "SectionError",
  components: {ListError},
}
</script>