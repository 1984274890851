// TODO Нужен рефакторинг

import {postCalculate} from "@/shared/api/tariff";
import {Tariff} from "@/features/calculate-tariff/Tariff";
// import {sortListTariff} from "@/features/calculate-tariff/sortListTariff";

export const getListTariffs = async (departure, departure_id, destination, estimated, weight, fast_delivery) =>{
    // const paramsTariffFast = paramsFormTariff(departure, departure_id, destination, estimated, weight, fast_delivery) // объект для скорой доставки
    const paramsTariffStandard = paramsFormTariff(departure, departure_id, destination, estimated, weight, !fast_delivery) // объект для стандартной доставки

    // const responseTariffFast = await postFormTariff(paramsTariffFast) // fetch для скорой доставки
    const responseTariffStandard = await postFormTariff(paramsTariffStandard) // fetch для стандартной доставки

    let listTariff;
    let tariffError;

    // if (responseTariffFast.success  || responseTariffStandard.success) {
    //     // хотя бы 1 успешный ответ
    //     listTariff = sortListTariff(responseTariffFast, responseTariffStandard)
    //     tariffError = null
    // }
    console.log(responseTariffStandard)
    if (responseTariffStandard.success) {
        // работаем только по стандартному
        listTariff = responseTariffStandard
        tariffError = null
    }
    else {
        // ошибка
        listTariff = null
        if(responseTariffStandard.status === 404){
            tariffError = {warn: 'attention', message: responseTariffStandard}
        } else {
            tariffError = {warn: 'error', message: responseTariffStandard}
        }
    }
    return {tariffError, listTariff}
}

export const paramsFormTariff = (departure, departure_id, destination, estimated, weight, type_delivery) => {
    return new Tariff(departure, departure_id, destination, estimated, weight, type_delivery)
}

export const postFormTariff = async obj => {
    let response = await postCalculate(obj);
    const status = response.status
    const success = status >= 200 && status <= 300

    if(!success){
        if(status === 400){
            response = 'Не удалось рассчитать доставку. Попробуйте позже.'
        } else if(status === 404){
            // response = 'Не удалось рассчитать доставку. Попробуйте изменить параметры поиска.'
            response = 'В данный момент направление недоступно. Попробуйте изменить параметры.'
        } else if(status === 408){
            response = 'Превышено время ожидания. Попробуйте позже.'
        } else if(status === 422){
            // P.S. Задача № 63124
            response = 'Неизвестная ошибка'
        }else if(status === 500 || status === 502 || status === 503 || status === 504){
            response = 'Ошибка сервера. Попробуйте позже.'
        }
    }
    return {success, status ,response}
}