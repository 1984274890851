<template>
    <!-- TODO почистить -->
<!--  :type="attributes.type"-->
<!--  :value="inputValue"-->
<!--  {{ inputValue.length }}-->
<!--  @paste="pastInputValue"-->
<!--  @paste="pastInputValue"-->

<!--  @paste="pasteInputValue($event)"-->

  <div>
<!--    v-debounce:500="getInputValue"-->
<!--    @paste="pasteInputValue($event)"-->
<!--    {{ inputValue }}-->
    <div
        class="input"
        :class="{
          'input--full': inputValue.length !== 0,
          'input--err': error.warn === 'error',
        }">
      <label class="input__label">
        <span>{{attributes.label}}</span>
        <input
            class="input__field"
            ref="input"
            :placeholder='attributes.placeholder'
            type="text"
            v-model="inputValue"
            @paste.prevent="pasteInputValue($event)"
            v-debounce:500="getInputValue"
            @keydown.enter="simulationBlurInput"
            @keydown.tab="simulationBlurInput"
            @input='e => this.inputValue = e.target.value'
            @blur="$emit('blurInput');"
            @focus="handleFocusInput"
            @click="selectText">
      </label>
      <div class="input__after" v-show="attributes.after">{{ attributes.after }}</div>
    </div>

<!--    инпут {{ pasteValue }}-->
<!--    инпутIn {{ inputValue }}-->
    <div
        v-if="error != null"
        :class="{
          'error': error.warn === 'error',
          'attention': error.warn === 'attention',
          'note': error.warn === 'note'
        }"
    >{{ error.message }}</div>
<!--    <div class="error" v-if="error.warn === 'error'">{{ error.message }}</div>-->
<!--    <div class="attention" v-if="error.warn === 'attention'">{{ error.message }}</div>-->
<!--    <div class="note" v-if="error.warn === 'note'">{{ error.message }}</div>-->
  </div>
</template>

<script>
import { vue3Debounce } from 'vue-debounce'
import {validSymbolsNumber} from "@/shared/libs/validation";
// import {ref} from "vue";
import {useScrollToInput} from "@/shared/libs/scrollTo";
export default {
  directives: {
    debounce: vue3Debounce({ lock: true, listenTo: 'keyup' })
  },
  name: "BaseInput",
  props: {
    class: {
      type: String,
      require: false
    },
    attributes:{
      type: Object,
      // type, label, placeholder, after, model, value
    },
    value:{
      type: String,
      require: false
    },
    invalid:{
      type: Object,
      // null or error, message
    },
  },
  emits:['getInputValue', 'blurInput', 'focusInput', 'clickInput', 'pasteInputValue'],
  data(){
    return{
      inputValue: '',
      paste: false,
      pasteValue: ''
    }
  },
  computed:{
    // вывести ошибку input
    error(){
      return this.invalid !== null ? this.invalid : false
    }
  },

  watch:{
    value(){
      if(this.value != null){
        this.$refs.input.value = this.value
        this.inputValue = this.value
      }
    },
    // attributes:{
    //   deep:true,
    //   handler() {
    //     if(this.attributes.value == null){
    //       // this.inputValue = ''
    //       // this.$refs.input.value = ''
    //       // this.inputValue = ''
    //     }
    //     // if(Object.keys(this.trackingStorage.track_code).length === 0){
    //     //   console.log('затерли')
    //     //   // из полей ввода удалена вся информация
    //     //   localStorage.clear()
    //     // } else {
    //     //   localStorage.setItem('trackingStorage', JSON.stringify(this.trackingStorage))
    //     // }
    //   }
    // },
    // inputValue(){
    //   this.pasteValue = this.inputValue
    // }
  },
  mounted() {
    if(this.attributes.value != null){
      this.inputValue = this.attributes.value
    } else if(this.value != null){
      this.inputValue = this.value
    }

    // this.$refs.input.addEventListener('paste', this.pasteInputValue);
  },
  methods: {

    handleFocusInput(){
      useScrollToInput(this.$refs.input)
      // console.log(this.$refs.form)
      // this.$refs.form.style.height = 1000 + 'px'
      this.$emit('focusInput')
    },

    simulationBlurInput(){
      this.$refs.input.blur()
    },

    validatedValue: function(value, model){
      if(value !== '' && (model === 'weight' || model === 'estimated')){
        // если модель вес или цена
        const mutationValue = this.inputValue = validSymbolsNumber(value)
        return Number(mutationValue).toFixed(2)
      } else {
        // если текст
        return value
      }
    },
    selectText: function (){
      this.$refs.input.select()
      this.$emit('clickInput')
    },
    offSelectText: function (){
      this.$refs.input.off('select')
    },
    getInputValue(){
      this.$emit('getInputValue', this.validatedValue(this.inputValue.trim(), this.attributes.model))

      // this.$emit('pasteInputValue', this.validatedValue(this.inputValue, this.attributes.model))
    },
    pasteInputValue(e){
      let clipboardData
      let pastedData;
      clipboardData = e.clipboardData || window.clipboardData;
      pastedData = clipboardData.getData('Text');

      this.inputValue = pastedData
      this.getInputValue()
      // this.$emit('pasteInputValue', this.inputValue)
    },
  }
}
</script>

<style scoped>
.input{
  background: #FFFFFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 16px;
  position: relative;
  border: 1px solid #e2e2e2;
  width: 100%;
}

.input__field{
  font-family: 'Montserrat';
  scroll-margin-top: 40px;
  -webkit-appearance: none;
  height: 100%;
  border: 0;
  outline: none;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #333333;
  background-color: inherit;
  flex: 1;
  padding-right: 16px;
}

.input__field:focus{
  background: inherit;
  outline: none;
  border: 0;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type='number'],
input[type="number"]:hover,
input[type="number"]:focus {
  appearance: none;
  -moz-appearance: textfield;
}

.input--full{
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.input--err{
  border-color: #E41F2C;
}

.input__label{
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #888888;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.input__label span{
  pointer-events: none;
  padding-right: 8px;
}

.input__after{
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 12px;
  line-height: 130%;
  text-align: right;
  color: #333333;
}

.error{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #E41F2C;
  margin-top: 4px;
}

.attention{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #FF9900;
  margin-top: 4px;
}

.note{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #333333;
  margin-top: 4px;
}

</style>