import {postTracking} from "@/shared/api/tracking";

export const getTracking = async str =>{
    const responseTracking = await postFormSearchTracking(str) // fetch

    let listTracking;
    let errorTracking;

    if (responseTracking.success) {
        // успешный ответ
        listTracking = responseTracking
        errorTracking = null
    } else {
        // ошибка
        listTracking = null
        errorTracking = {warn: 'error', message: responseTracking}
    }
    return {errorTracking, listTracking}
}

const postFormSearchTracking = async str => {
    let response = await postTracking(str);
    const status = response.status
    const success = status >= 200 && status <= 300

    if(!success){
        if(status === 400){
            response = 'Не удалось отследить статус. Попробуйте позже.'
        } else if(status === 404){
            response = 'Не найдено'
        } else if(status === 408){
            response = 'Превышено время ожидания. Попробуйте позже.'
        } else if(status === 422){
            // P.S. Задача № 63124
            response = 'Неизвестная ошибка'
        }else if(status === 500 || status === 502 || status === 503 || status === 504){
            response = 'Ошибка сервера. Попробуйте позже.'
        }
    }
    return {success, response}
}