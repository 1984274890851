<template>
  <base-dropdown :command-visible="commandVisibleDropdown">
    <template v-slot:head>
      <base-input
          :attributes="{label: 'Откуда:', type: 'text', model: 'departure'}"
          :invalid="selectedPointError"
          :value="selectedPlace"
          @getInputValue="handleValueSearch"
          @blurInput="handleBlurInput"
          @clickInput="handleClickInput"
      ></base-input>
    </template>
    <template v-slot:body>
      <list-points-departure
          :search="search"
          :default-list="pointsListDefault"
          :points="pointsList"
          :visible-skeleton="loadingResponse"
          @selectPoint="handleSelectedPoint"
          @selectLocation="handleSelectedLocation"
      ></list-points-departure>
    </template>
  </base-dropdown>
</template>

<script>
import BaseDropdown from "@/shared/ui/BaseDropdown";
import BaseInput from "@/shared/ui/BaseInput";
import {validMinSymbolsForSelectSearch} from "@/shared/libs/validation";
import {ignoreKeyboardLayout} from "@/shared/libs/ignoreKeyboardLayout";
import {getPointsDeparture} from "@/entities/point/departure/getPointsDeparture";
import ListPointsDeparture from "@/entities/point/departure/ListPointsDeparture";

export default {
  name: "SelectDeparture",
  components: {ListPointsDeparture, BaseInput, BaseDropdown},
  emits:['selectPoint', 'selectLocation'],
  data(){
    return{
      search: null,
      pointsList: null,
      pointsListDefault: false,

      selectedPoint: null,
      selectedPointError: null,
      selectedLocation: null,

      commandVisibleDropdown: false,
      loadingResponse: false
    }
  },
  beforeMount() {
    const savedData = localStorage.getItem('storageCalculate')
    if(savedData != null){
      // есть сохраненные данные
      const res = JSON.parse(savedData);
      this.handleSelectedPoint(res.departure) // иммитирует выбор города
      this.handleSelectedLocation(res.departure_location) // иммитирует выбор локации
    }
  },
  computed:{
    selectedPlace(){
      if(this.selectedPoint != null){
        return this.selectedPoint.place
      } else {
        return null
      }
    }
  },
  watch:{
    selectedPoint(){
      this.emitSelectPoint()
    }
  },
  methods:{
    // Обрабатывает значение из BaseInput
    handleValueSearch: function(str){
      this.search = validMinSymbolsForSelectSearch(ignoreKeyboardLayout(str.toLowerCase()), 2)
      this.selectedPoint = null
      this.handleEventInput()
    },

    handleEventInput: function(){
      if(this.search != null){
        this.commandVisibleDropdown = true
        this.getListPoints()
      } else {
        this.selectedPoint = null
        this.commandVisibleDropdown = false
      }
    },

    // Обрабатывает событие click из BaseInput
    handleClickInput: function(){
      if(this.search == null){
        this.search = this.selectedPlace
      }
      this.handleEventInput()
    },

    // Обрабатывает событие blur из BaseInput
    handleBlurInput: function (){
      if(this.search == null){
        this.selectedPointError = { warn: 'error', message: 'Выберите город из предложенного списка' }
      } else {
        this.selectedPointError = null
      }
    },

    // Получает список городов по поисковому запросу через api
    getListPoints: async function(){
      this.loadingResponse = true // включаем скелетон
      const points = await getPointsDeparture(this.search)
      this.loadingResponse = false // выключаем скелетон
      this.pointsList = points.listSort
      this.pointsListDefault = points.listDefault
    },

    // Обрабатывает выбранный город из шаблона ListPoints
    handleSelectedPoint: function(point){
      this.selectedPoint = point
      this.search = point.place
      this.commandVisibleDropdown = false
      this.emitSelectPoint()
    },
    // Обрабатывает выбранный адрес из шаблона ListPoints
    handleSelectedLocation: function(location){
      this.selectedLocation = location
      this.commandVisibleDropdown = false
      this.emitSelectLocation()
    },

    emitSelectPoint: function (){
      this.$emit('selectPoint', this.selectedPoint)
    },

    emitSelectLocation: function (){
      this.$emit('selectLocation', this.selectedLocation)
    }

  },
}
</script>