<template>
  <div class="card">
    <div class="card__profile">
      <div class="infographic"></div>
      <div>
        <div class="title">{{ address }}</div>
        <base-link :content="standardFormatPhone" :href="'tel:' + phone"></base-link>
      </div>
    </div>
    <div v-if="isPaidExport" class="container-warn">
      <base-warn type="attention" message="На этом адресе действует ПЛАТНЫЙ вывоз груза, если вы не отправляете его услугами нашей компании"></base-warn>
    </div>
  </div>
</template>

<script>
import {formattedPhone} from "@/shared/libs/formattedPhone";
import BaseWarn from "@/shared/ui/BaseWarn";
import BaseLink from "@/shared/ui/BaseLink";
import {mapGetters} from "vuex";
export default {
  name: "CardAddress",
  components: {BaseLink, BaseWarn},
  props:{
    address: String,
    phone: String
  },
  computed:{
    ...mapGetters(['getListTariff']),
    standardFormatPhone(){
      return formattedPhone(this.phone)
    },
    isPaidExport(){
      // return this.getListTariff[0].mark_up_weight !== 0
      return this.getListTariff.response.result.mark_up_weight !== 0
    }
  }
}
</script>

<style scoped>
.container-warn{
  margin-top: 16px;
}
.card{
  display: block;
  background: #FFFFFF;
  border-radius: 10px;
  padding: 16px 24px;
  position: relative;
  border: 1px solid #e2e2e2;

  text-decoration: none;
  color: inherit;

  transition: 200ms;

  min-height: 100px;
}

.card__profile{
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.title{
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 4px;
  font-weight: 600;
}

.phone{
  font-size: 14px;
  line-height: 20px;
  /*color: #333333;*/
  margin-bottom: 4px;
  text-decoration: none;
  color: #11A640;
  font-weight: 600;
}

.infographic{
  /*position: absolute;*/
  /*left: 24px;*/
  /*top: 24px;*/
  min-width: 75px;
  width: 75px;
  height: 75px;
  margin-right: 24px;
  background: url("../../shared/assets/image/ill_roadstorage.png") no-repeat left top / contain;
}
</style>