<template>
  <form>
    <div class="form__line">
      <div class="base-input--width">
        <base-input
            :attributes="{ label: 'УИН:', type: 'text', model: 'track'}"
            :invalid="trackCodeError"
            :value="setTrackCode"
            @getInputValue="handleValueTrackCode"
        ></base-input>
      </div>
      <base-button-icon
          class="base-button-icon--margin"
          :refresh="refreshForm"
          :isLoading="loadingResponse"
          view="search"
          :disabled="trackCodeInvalid"
          @click.prevent="getTracking"
      ></base-button-icon>
    </div>
  </form>
</template>

<script>
import BaseButtonIcon from "@/shared/ui/BaseButtonIcon";
import BaseInput from "@/shared/ui/BaseInput";
import {getTracking} from "@/features/search-tracking/postSearchTracking";
import {validSymbolsForTracking} from "@/features/search-tracking/validation";
import {scrollToFrame} from "@/shared/libs/scrollTo";
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: "FormTracking",
  components: {BaseInput, BaseButtonIcon},
  data(){
    return{
      trackCode: null,
      trackCodeError: null, // дефолтное, нигде не перезаписывается
      trackCodeInvalid: true,
      refreshForm: false,
      setTrackCode: null // перезаписывает трек код, используется только для local storage
    }
  },
  computed:{
    ...mapGetters(['stateLoadingResponseTracking']),
    loadingResponse(){
      return this.stateLoadingResponseTracking
    }
  },
  beforeMount() {
    const savedData = localStorage.getItem('storageTracking')
    if(savedData != null){
      // есть сохраненные данные
      const res = JSON.parse(savedData);
      this.setTrackCode = res.track_code
      this.handleValueTrackCode(this.setTrackCode)
    }
  },
  watch:{
    trackCode(){
      this.handleFullnessFormTracking()
    }
  },
  methods: {
    ...mapMutations([
        'waitingResponseTracking',
        'updatePathTracking',
        'updateRoadmapTracking',
        'updateError',
        'updatePathStart'
    ]),

    handleValueTrackCode: function (str){
      const code = validSymbolsForTracking(str)
      this.trackCodeInvalid = code === null;
      this.trackCode = code// записываем
    },

    getTracking: async function(){
      this.updatePathTracking(true) // // показываем section-result-tracking
      this.waitingResponseTracking(true) // // показываем скелетон
      scrollToFrame() // выдвигаем шторку
      const response = await getTracking(this.trackCode) // fetch
      const list = response.listTracking
      const error = response.errorTracking
      this.refreshForm = error != null; // меняет статус кнопки "Повторить", взависимости от того, есть ли ошибка в результате
      this.updateRoadmapTracking(list) // записываем ответ результата
      this.updateError(error) // записываем ответ ошибки
      this.waitingResponseTracking(false) // выключаем скелетон
    },

    // Обрабатывает заполнение формы
    handleFullnessFormTracking: function (){
      if(this.trackCode != null){
        // форма заполнена полностью
        this.addLocalStorage() // записывает параметры расчета в local storage
      } else {
        // форма очищена
        this.refreshForm = false // отключает кнопку "Повторить"
        this.cleanLocalStorage() // очистка local storage
        this.updatePathStart(true)
      }
    },

    // Записывает в local storage
    addLocalStorage: function (){
      const params = {
        track_code: this.trackCode,
      };
      localStorage.setItem('storageTracking', JSON.stringify(params))
      localStorage.removeItem('storageCalculate')
    },

    // Очищает local storage
    cleanLocalStorage: function (){
      localStorage.removeItem('storageTracking')
    }
  }
}
</script>

<style scoped>
.base-button-icon--margin{
  margin-left: 8px;
}
.base-input--width{
   flex: 1;
 }
.form__line{
  display: flex;
  align-items: flex-start;
}
</style>