// import {ref} from "vue";

import {handleWindowResize} from "@/shared/libs/windowListener";

export const scrollToFrame = function (){
    let access = document.getElementById("frame");

    if(handleWindowResize() < 900){
        access.scrollIntoView({behavior: 'smooth'}, true);
    }
}

export function useScrollToInput(element){
    const access = element
    const ios = navigator.appVersion.indexOf('iPhone') >= 0

    // function scrollToInput(){
        if(handleWindowResize() < 900 && !ios){
            access.scrollIntoView({behavior: 'smooth'}, true);

            // window.scrollBy({
            //     top: 60,
            //     behavior: 'smooth'
            // });

        }
    // }

    // return {scrollTo}
}