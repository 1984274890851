<template>
  <div class="base-warn-cookie">
    <div class="base-warn-cookie__content">
      <p>Мы используем cookie. Это позволяет нам анализировать взаимодействие посетителей с сайтом и делать его лучше. Продолжая пользоваться сайтом, вы соглашаетесь с использованием файлов cookie.</p>
    </div>
    <div class="base-warn-cookie__container-button">
      <base-button
          content="Ок"
          borderColor="black"
          :long="true"
          @buttonClick="$emit('eventHideWarm')"
      ></base-button>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/shared/ui/BaseButton";
export default {
  name: "BaseWarnCookie",
  components: {BaseButton},
  emits:['eventHideWarm']
}
</script>

<style scoped>
.base-warn-cookie{
  background: rgba(255, 255, 255, 0.90);
  border-radius: 10px 10px 0 0;
  padding: 16px 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 3;
}

.base-warn-cookie:before{
  content: '';
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 0 0;
}


.base-warn-cookie__content{
  margin-bottom: 16px;
}


@media screen and (min-width: 768px){
  .base-warn-cookie{
    display: flex;
    justify-content: space-between;
    align-items: center;
    bottom: 24px;
    width: 100%;
    max-width: 871px;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    border-radius: 10px;
  }

  .base-warn-cookie:before{
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    border-radius: 10px;
  }

  .base-warn-cookie__content{
    max-width: 655px;
    margin-bottom: 0;
  }
  .base-warn-cookie__container-button{
    max-width: 84px;
  }
}
</style>