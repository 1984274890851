<template>
  <a class="link" :target="isNewWindow" :href="clickableLink ? clickableLink : ''">{{ content }}</a>
</template>

<script>
export default {
  name: "BaseLink",
  props:{
    content: String,
    link: String,
    newWindow: {
      type: Boolean,
      require: false,
      default: false
    }
  },
  computed:{
    isNewWindow(){
      if(this.newWindow){
        return '_blank'
      } else {
        return '_sel'
      }

    },
    clickableLink(){
      return this.link !== null ? this.link : false
    }
  }
}
</script>

<style scoped>
.link{
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-decoration: none;
  color: #323FD4;
  display: inline-block;
  cursor: pointer;
  transition: 100ms;
}
.link:hover{
  color: #121EA9;
}
</style>