<template>
  <div class="tooltip">
    <a
        class="tooltip__button"
        v-html="headlineContent"
        @click="visibleAreaTooltip(true)"
    ></a>
    <transition name="fade">
      <div class="tooltip__body" v-if="statusAreaTooltip">
        <slot></slot>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "BaseTooltip",
  props:{
    headline:{
      type: String,
      require: false
    },
    body:{
      type: Object
    }
  },
  data(){
    return {
      statusAreaTooltip: false
    }
  },
  computed:{
    headlineContent(){
      return this.headline ? this.headline : ''
    }
  },
  mounted() {
    document.addEventListener('click', this.visibleAreaTooltip.bind(this, false), true)
  },
  beforeUnmount() {
    document.removeEventListener('click', this.visibleAreaTooltip(false))
  },
  methods:{
    visibleAreaTooltip: function (boolean = false){
      this.statusAreaTooltip = boolean
    },
  },
}
</script>

<style>
.tooltip__body p{
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: #333333;
  margin: 8px 0;
}

.tooltip__body p:first-child{
  margin-top: 0;
}
</style>

<style scoped>
.tooltip{
  position: relative;
}

.tooltip__button{
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: #323FD4;
  cursor: pointer;
  transition: 100ms;
  padding-left: 30px;
  position: relative;
  display: block;
}

.tooltip__button:before{
  content: '';
  width: 20px;
  height: 20px;
  background: url("../assets/image/ic_help.svg") no-repeat center / contain;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-59%);
}

.tooltip__button:hover{
  color: #121EA9;
}
.tooltip__body{
  position: absolute;
  top: 24px;
  left: 0;
  /*transform: translate(100%, 100%);*/
  z-index: 2;
  max-width: 232px;
  background: rgba(255, 255, 255, 0.90);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  /* Note: backdrop-filter has minimal browser support */
  border-radius: 10px;
  padding: 12px 16px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>