<template>
    <div id='frame' class="frame-content">
      <div class="frame-content__inner">
        <div class="frame-content__body">
          <button-help></button-help>
          <!-- Внутри динамического компонента -->
          <!-- <section-result-tracking></section-result-tracking> -->
          <!-- <section-result-tariff></section-result-tariff> -->
          <!-- <section-information></section-information> -->
          <!-- <section-error></section-error> -->
          <component :is="activeFrame"></component>
        </div>
        <div class="hr hr--full"></div>
        <section-footer></section-footer>
      </div>
    </div>
</template>

<script>
import SectionResultTariff from "@/widgets/tariff/SectionResultTariff";
import SectionResultTracking from "@/widgets/tracking/SectionResultTracking";
import SectionFooter from "@/widgets/SectionFooter";
import SectionInformation from "@/widgets/start/SectionInformation";
import SectionError from "@/widgets/errors/SectionError";
import { mapGetters } from 'vuex'
import ButtonHelp from "@/features/show-help/ButtonHelp";
export default {
  name: "FrameContent",
  components: {
    ButtonHelp,
    SectionError, SectionInformation, SectionResultTracking, SectionFooter, SectionResultTariff},
  computed: {
    ...mapGetters([
      'isPathError',
      'isPathStart',
      'isPathCalculate',
      'isPathTracking',
    ]),
    activeFrame() {
      if (this.isPathTracking) {
        return 'SectionResultTracking'
      } else if (this.isPathCalculate) {
        return 'SectionResultTariff'
      } else if (this.isPathError) {
        return 'SectionError'
      } else {
        return 'SectionInformation'
      }
    },
  }
}
</script>

<style scoped>
.frame-content{
  width: 100%;
  background-color: #fff;
  border-radius: 20px 20px 0 0;
  padding: 32px;
  position: relative;
  margin-top: 48px;
  transform: translateY(0);
}

@media screen and (min-width: 900px){
  .frame-content{
    width: 100%;
    padding: 0;
    position: relative;
    border-radius: 0;
    background-color: transparent;
    margin-left: 64px;
    min-width: 418px;
    max-width: 418px;
    margin-top: 0;
    transform: translateY(0);
  }
  .frame-content:before{
    content: "";
    position: absolute;
    width: 380px;
    height: 83px;
    background: url("../shared/assets/image/before-for-result.svg") no-repeat left / 100%;
    border-radius: 20px;
    left: 0;
    top: -41px;
  }
}

.frame-content__body{
  flex: 1;
}

.frame-content__inner{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: inherit;
}
@media screen and (min-width: 900px){
  .frame-content__inner{
    border-radius: 20px;
    padding: 32px 48px;
    background-color: #fff;
    position: relative;
    z-index: 1;
    height: 100%;
    min-height: 500px;
  }
}



</style>