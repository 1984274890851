<template>
  <ul v-if="visibleSkeleton" class="list">
    <li class="item skeleton" v-for="(skeleton, index) in skeletons" :key="index">
      <div class="title"></div>
      <div class="description"></div>
    </li>
  </ul>

  <template v-else>
    <template v-if="defaultList">
      <div class="underfunded">По запросу ничего не найдено</div>
      <div class="container-default-list__title">
        <base-h3 content="Доступные города для отправки"></base-h3>
      </div>
    </template>

    <div v-else-if="points == null" class="underfunded">По запросу ничего не найдено</div>

    <ul class="list">
      <li
          class="item"
          v-for="point in points"
          :key='point.postcode'
          @mousedown="clickOnPoint(point)"
      >
        <div class="title">
          <div> {{point.place_type}} <span v-html="highlight(point.place, search)"></span></div>
          <div class="description" v-show="point.location || point.region">
            <span>{{ point.location }}</span>
            <span> {{ point.region }}</span>
          </div>
        </div>
        <div class="description">Индекс: <span v-html="highlight(point.postcode, search)"></span></div>
      </li>
    </ul>
  </template>
</template>

<script>
import BaseH3 from "@/shared/ui/BaseH3";
import { highlightText } from "@/shared/libs/highlightText"
export default {
name: "ListPoints",
  components: {BaseH3},
  props: {
    points:{
      type: Object
    },
    search: String,
    visibleSkeleton: Boolean,
    defaultList: {
      type: Boolean,
      require: false
    }
  },
  emits: ["selectPoint"],
  data(){
    return{
      skeletons: ['1', '2', '3']
    }
  },
  methods:{
    highlight(text, search){
      return highlightText(text, search)
    },
    clickOnPoint(item){
      this.$emit("selectPoint", item)
    },
  },
}
</script>

<style scoped>
.title{
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 4px;
  display: flex;
  align-items: baseline;
  flex-wrap: nowrap;
  white-space: nowrap;
}

.title .description{
  padding-left: 3px;
  display: inline;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.description{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #9A9B9A;
}

.description span{
  padding-right: 3px;
}

.description span:last-child{
  padding-right: 0;
}

.underfunded{
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #9A9B9A;
  padding: 8px 16px;
}

.container-default-list__title{
  padding: 16px 16px 8px;
  position: relative;
}

.item{
  list-style: none;
  padding: 10px 16px;
  position: relative;
  cursor: pointer;
  transition: 200ms;
}

.item > *{
  pointer-events: none;
}

.container-default-list__title:after,
.item:after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 16px;
  right: 16px;
  height: 1px;
  background-color: #F3F3F4;
}

.item:last-child:after{
  display: none;
}

.item:focus,
.item:hover{
  background-color: #F3F3F4;
}

.skeleton .title{
  width: 100px;
  height: 18px;
  border-radius: 2px;
  background: linear-gradient(90deg, #CFCFCF 0%, #F5F5F5 53.13%, #CECDCD 99.48%);
  background-size: 200%;
}

.skeleton .description{
  width: 150px;
  height: 12px;
  background: linear-gradient(90deg, #E1E1E1 0%, #E9E8E8 100%);
  opacity: 50%;
  margin: 4px 0;
}
</style>