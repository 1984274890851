<template>
  <div v-if="skeleton" class="skeleton">
    <div class="title"></div>
    <ul class="list">
      <li class="item"><div class="description"></div></li>
      <li class="item"><div class="description"></div></li>
    </ul>
  </div>
  <div
      v-else
      class="card"
      :class="{
        'card--type-departure' : type === 'departure',
        'card--type-destination' : type === 'destination'
      }"
  >
    <div class="title"> {{ item.place_type }} {{ item.place }}</div>
    <ul class="list">
      <li
          v-if="itemAddress !== null"
          class="item">
        <div class="description">
          <span class="description__label">Адрес: </span>
          {{ itemAddress }}
        </div>
      </li>
      <li
          v-show="item.region"
          class="item"
      >
        <div class="description">
          <span class="description__label">Регион: </span>
          {{ item.region }}
        </div>
      </li>
      <li
          v-show="item.area || item.location"
          class="item">
        <div class="description">
          <span class="description__label">Район: </span>
          {{ item.area }} {{ item.location }}
        </div>
      </li>
      <li
          v-show="item.postcode"
          class="item"
      >
        <div class="description">
          <span class="description__label">Индекс: </span>
          {{ item.postcode }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "CardPoint",
  props: {
    point: Object,
    location: {
      type: Object,
      require: false
    },
    skeleton: Boolean,
    type: {
      type: String,
      require: false
    }
  },
  data(){
    return{
      item: null,
      itemAddress: null
    }
  },
  watch:{
    point(){
      this.checkEmptyPoint()
    }
  },
  beforeMount() {
    this.checkEmptyPoint()
  },
  methods:{
    checkEmptyPoint: function (){
      if (this.point == null){
        // массив пустой или значение поля place равно null
        this.item =  {
          place: 'Не выбрано',
          address: 'Не выбрано',
          region: 'Не выбрано',
          location: 'Не выбрано',
          area: 'Не выбрано',
          postcode: 'Не выбрано',
        }
      } else {
        this.item = this.point
        if(this.location !== null){
          this.itemAddress = this.location.address_ops
        }

      }
    }
  }
}
</script>
<style scoped>
.card{
  position: relative;
  padding-left: 32px;
}

.card:before{
  content: '';
  width: 4px;
  background: url("../../shared/assets/image/ic_circle.svg") repeat-y center / auto;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
}

.card--type-destination:after,
.card--type-departure:after{
  content: '';
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-7px);
  background: repeat-y center / auto;;
}

.card--type-destination:after{
  background-image: url("../../shared/assets/image/ic_destination.svg");
  bottom: 0;
  top: auto;
}

.card--type-departure:after{
  background-image: url("../../shared/assets/image/ic_departure.svg");
}

.title{
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #333333;
  margin-bottom: 4px;
}

.description{
  font-weight: 400;
  font-size: 12px;
  line-height: 17px;
  color: #333333;
}

.description__label{
  font-weight: 600;
  color: #9A9B9A;
  text-transform: uppercase;
  font-size: 11px;
}

.item{
  list-style: none;
  margin: 4px 0;
}

/* Скелетон */
.skeleton .title{
  width: 100px;
  height: 20px;
  border-radius: 2px;
  background: linear-gradient(90deg, #CFCFCF 0%, #F5F5F5 53.13%, #CECDCD 99.48%);
  background-size: 200%;
}
.skeleton .description{
  width: 150px;
  height: 18px;
  background: linear-gradient(90deg, #E1E1E1 0%, #E9E8E8 100%);
  opacity: 50%;
}

</style>