export default {
    mutations: {
        updateTrackCode(state, str){
            state.trackCode = str
        },
        updateRoadmapTracking(state, obj){
            state.roadmap = obj
        },
        waitingResponseTracking(state, flag){
            state.loadingResponseTracking = flag
        },
    },
    state: {
        trackCode: null,
        roadmap: null,
        loadingResponseTracking: false,
    },
    getters: {
        getListRoadmap(state){
            return state.roadmap
        },
        trackCode(state){
            return state.trackCode
        },
        stateLoadingResponseTracking(state){
            return state.loadingResponseTracking
        }
    }
}