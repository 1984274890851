<template>
  <div class="button-info" @click="activeModalHelp">
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="12" cy="12" r="11.5" stroke="#323FD4"/>
      <path d="M13.2192 6C14.0232 6 14.4252 6.5472 14.4252 7.1742C14.4252 7.9572 13.7268 8.6814 12.8178 8.6814C12.0564 8.6814 11.6124 8.2314 11.6334 7.4874C11.6334 6.8616 12.162 6 13.2192 6ZM10.7454 18C10.1106 18 9.6456 17.6088 10.0896 15.8856L10.818 12.8304C10.9446 12.342 10.9656 12.1458 10.818 12.1458C10.6278 12.1458 9.8046 12.483 9.3168 12.816L9 12.288C10.5432 10.9764 12.3186 10.2078 13.0806 10.2078C13.7148 10.2078 13.8204 10.9716 13.5036 12.1458L12.669 15.357C12.5214 15.924 12.5844 16.1196 12.7326 16.1196C12.9228 16.1196 13.5468 15.8844 14.16 15.3954L14.52 15.8838C13.0188 17.412 11.379 18 10.7454 18Z" fill="#323FD4"/>
    </svg>
  </div>
</template>

<script>
import {mapMutations} from "vuex";

export default {
  name: "ButtonHelp",
  methods:{
      ...mapMutations(['updatePathModalHelp']),
    activeModalHelp: function (){
      this.updatePathModalHelp(true)
    }
  }
}
</script>

<style scoped>
.button-info{
  position: absolute;
  top: 24px;
  right: 24px;
  cursor: pointer;
  transition: 100ms;
}
.button-info:hover circle{
  stroke: #121EA9;
}
.button-info:hover path{
  fill: #121EA9;
}
</style>