import {getDeparture} from "@/shared/api/points";
import {sortByKey} from "@/shared/libs/sort";

export const getPointsDeparture = async str => {
    let listDefault
    let listSort

    let fetch = await getDeparture(false, str);
    let response = await fetch.json();

    if(response.length === 0){
        response = null
    }

    if(response == null){
        fetch = await getDeparture(true);
        response = await fetch.json();
        listDefault = true
        listSort = response
    } else {
        listDefault = false
        listSort = sortByKey(response, 'place', str);
    }

    return {listDefault, listSort}
}