<template>
  <base-warn-top v-if="isTestVersion"></base-warn-top>
  <div
      class="page"
      :class="{
          'page--margin-top' : isTestVersion
      }"
  >
    <div class="wrap">
      <div class="container">
        <frame-forms></frame-forms>
        <frame-content></frame-content>
      </div>

      <base-warn-cookie v-if="!isAcceptCookie" @eventHideWarm="handleEventHideWarnCookie"></base-warn-cookie>
    </div>
  </div>

</template>

<script>
import FrameForms from "@/templates/FrameForms";
import FrameContent from "@/templates/FrameContent";
import BaseWarnTop from "@/shared/ui/BaseWarnTop";
import BaseWarnCookie from "@/shared/ui/BaseWarnCookie";
export default {
  name: "PageMain",
  components: {BaseWarnCookie, BaseWarnTop, FrameForms, FrameContent},
  data(){
    return{
      isTestVersion: false,
      isAcceptCookie: false
    }
  },
  mounted() {
    // показывает уведомление о local storage, если хранилице браузера пустое
    this.isAcceptCookie = localStorage.getItem('isAcceptCookie') != null

    // показывает warn о тестовой версии, если url не содержит box.sibtrans.ru
    this.isTestVersion = window.location.href.indexOf('box.sibtrans.ru') === -1;
  },
  methods:{
    handleEventHideWarnCookie: function (){
      this.isAcceptCookie = true
      localStorage.setItem('isAcceptCookie', JSON.stringify(this.isAcceptCookie))
    }
  },
}
</script>

<style>
.page--margin-top{
  margin-top: 90px;
}
@media screen and (min-width: 768px){
  .page--margin-top{
    margin-top: 55px;
  }
}
</style>
