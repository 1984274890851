<template>
  <button class="button-back" @click="$emit('eventBack')">
    <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.2879 1.78788L10.4899 0L0.5 10L10.5 20L12.2879 18.2121L4.07576 10L12.2879 1.78788V1.78788Z" fill="#333333"/>
    </svg>
  </button>
</template>

<script>
export default {
  name: "BaseButtonBack",
  emits: ['eventBack']
}
</script>

<style scoped>
.button-back{
  width: 50px;
  height: 50px;
  position: fixed;

  top: 64px;
  left: 50%;
  transform: translateX(-1040%);

  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

  border: 0;
  outline: none;

  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.button-back svg{
  position: relative;
  left: -2px;
}

@media screen and (max-width: 1100px){
  .button-back{
    width: 40px;
    height: 40px;
    position: absolute;

    top: 48px;
    left: 32px;
    transform: none;
  }

  .button-back svg{
    width: 16px;
    height: 16px;
  }
}

@media screen and (min-width: 1440px){
  .button-back{
    transform: translateX(-600px);
  }
}
</style>