<template>
  <div v-if="skeleton" class="card-tariff skeleton">
    <div class="card-tariff__infographics"></div>
    <div class="card-tariff__content">
      <p class="card-tariff__main-text"></p>
      <ul class="card-tariff__list">
        <li class="card-tariff__item"><p class="card-tariff__description"></p></li>
        <li class="card-tariff__item"><p class="card-tariff__description"></p></li>
      </ul>
    </div>
  </div>

  <a v-else class="card-tariff" @click="$emit('selectTariff')">
    <div v-html="titleTypeTariff"></div>
    <div
        class="card-tariff__infographics"
        :class="{
          'card-tariff__infographics--fast': dataTariff.fast_delivery
        }"
    ></div>
    <div class="card-tariff__content">
      <p class="card-tariff__main-text">{{ formattedTotalAmount }} Р</p>
      <ul class="card-tariff__list">
        <li class="card-tariff__item">
          <p class="card-tariff__description">
            <span v-if="dataTariff.delivery_min !== dataTariff.delivery_max">от {{ dataTariff.delivery_min }}</span>
            <span> до {{ dataTariff.delivery_max }} {{ formattedDayTitle }}</span>
          </p>
        </li>
        <li class="card-tariff__item">
          <p v-if="fewNumberOfSeats" class="card-tariff__description card-tariff__description--light">Несколько мест</p>
          <p v-else class="card-tariff__description card-tariff__description--light">1 место</p>
        </li>
      </ul>
    </div>
    <div class="card__link"></div>
  </a>
</template>

<script>
import {formattedDayTitle} from "@/shared/libs/formattedWord";
import {mapGetters} from "vuex";

export default {
  name: "CardTariff",
  props: {
    dataTariff: {
      type: Object,
      require: false
    },
    skeleton: {
      type: Boolean,
      require: false
    },
  },
  emits:['selectTariff'],
  computed:{
    ...mapGetters(['getWeight']),
    titleTypeTariff(){
      if(this.dataTariff.fast_delivery){
        return `<div class="card-tariff__label card-tariff__label--red">Скорый</div>`
      }
      return `<div class="card-tariff__label" style="display: none">Стандартный</div>`
    },
    formattedDayTitle(){
      return formattedDayTitle(this.dataTariff.delivery_max)
    },
    formattedTotalAmount(){
      console.log(this.dataTariff)
      return Number(this.dataTariff.total_amount).toLocaleString()
    },
    fewNumberOfSeats(){
      return this.getWeight / 30 > 1
    }
  },
}
</script>

<style>
.card-tariff{
  padding: 20px 24px 22px;
  background-color: #FFFFFF;
  border-radius: 10px;
  position: relative;
  text-decoration: none;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid #e2e2e2;
  transition: 200ms;
  /*box-shadow: -2px 4px 10px -8px rgba(0, 0, 0, 0.1);*/
}
.card-tariff:hover{
  box-shadow: 0 12px 22px -12px rgba(0, 0, 0, 0.1)
}
.card-tariff.skeleton:hover{
  box-shadow: none;
}
.card-tariff__label{
  padding: 4px 12px 5px;
  font-size: 12px;
  font-weight: 600;
  color: #fff;
  border-radius: 24px;
  position: absolute;
  top: -12px;
  left: 8px;
  background-color: #11A640;
}
.card-tariff__label--red{
  background-color: #A62321;
}
.card-tariff__infographics{
  width: 100px;
  height: 60px;
  margin-right: 16px;
  background: url("../../shared/assets/image/ill_deliveryStandard.png") no-repeat left center / contain;
}

.card-tariff__infographics--fast{
  /*background: url("../../shared/assets/image/ill_deliveryFast.svg") no-repeat left center / contain;*/
  background: url("../../shared/assets/image/ill_deliveryFast.png") no-repeat left center / contain;
}

.card-tariff__main-text{
  font-weight: 700;
  font-size: 16px;
  line-height: 100%;
  color: #323FD4;
  margin-bottom: 4px;
}
.card-tariff__list{
  list-style: none;
}
.card-tariff__description{
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #333;
}
.card-tariff__description--light{
  color: #9F9CB6;
}
.card-tariff__item{
  margin-bottom: 4px;
}
.card-tariff__item:last-child{
  margin-bottom: 0;
}
.skeleton .card-tariff__infographics{
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: linear-gradient(90deg, #CFCFCF 0%, #F5F5F5 53.13%, #CECDCD 99.48%);
  margin-right: 16px;
}
.skeleton .card-tariff__main-text{
  width: 100px;
  height: 16px;
  background: linear-gradient(90deg, #CFCFCF 0%, #F5F5F5 53.13%, #CECDCD 99.48%);
}
.skeleton .card-tariff__description{
  width: 160px;
  height: 16px;
  background: linear-gradient(90deg, #CFCFCF 0%, #F5F5F5 53.13%, #CECDCD 99.48%);
}
.card__link{
  width: 9px;
  height: 16px;
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  background: url("../../shared/assets/image/ic_link.svg") no-repeat center / contain;
}
</style>