<template>
  <base-h3 class="base-h3--margin" content="Информация об отслеживании:"></base-h3>
  <about-tracking :skeleton="skeleton" :data="listRoadmap"></about-tracking>
  <base-h3 class="base-h3--margin" content="Статус отслеживания:"></base-h3>
  <list-roadmap :skeleton="skeleton" :list-roadmap="listRoadmap"></list-roadmap>
</template>

<script>
import BaseH3 from "@/shared/ui/BaseH3";
import ListRoadmap from "@/entities/tracking/ListRoadmap";
import AboutTracking from "@/entities/tracking/AboutTracking";
import {mapGetters} from "vuex";
export default {
  name: "SectionResultTracking",
  components: {AboutTracking, ListRoadmap, BaseH3},
  computed:{
    ...mapGetters(['stateLoadingResponseTracking', 'getListRoadmap']),
    skeleton(){
      return this.stateLoadingResponseTracking
    },
    listRoadmap(){
      if(this.getListRoadmap != null){
        return this.getListRoadmap.response.result
      }
        return null
    }
  },
}
</script>

<style scoped>
.base-h3--margin{
  margin-bottom: 24px;
}
</style>