<template>
  <ul class="roadmap">

    <template v-if="skeleton">
      <li class="roadmap__item">
        <div class="roadmap__infographic"></div>
        <div class="skeleton">
          <div class="roadmap__title"></div>
          <div class="roadmap__description"></div>
        </div>
      </li>

      <li class="roadmap__item">
        <div class="roadmap__infographic"></div>
        <div class="skeleton">
          <div class="roadmap__title"></div>
          <div class="roadmap__description"></div>
        </div>
      </li>
    </template>

    <template v-else>
      <!-- Пункт назначения
           показывается, если статус недоставлено
       -->
      <li
          v-if="!isDelivered"
          class="roadmap__item roadmap__item--disabled"
      >
        <div class="roadmap__infographic"></div>
        <div
            v-if="dataTracking.place_destination != null"
            class="roadmap__description"
        >{{ dataTracking.place_destination }}</div>
        <div
            v-else-if="dataTracking.region_destination != null"
            class="roadmap__description"
        >{{ dataTracking.region_destination }}</div>
        <div class="roadmap__description" v-else>Россия</div>
        <div
            v-if="dataTracking.delivery_max != null"
            class="roadmap__description">
          <span> ~ {{ formatDate(dataTracking.delivery_max) }}</span>
        </div>
      </li>

      <!-- Остальная история перемещения -->
      <li
          v-for="item in reverseRoadmap"
          :key="item.status_index"
          class="roadmap__item"
          :class="{
          'roadmap__item--disabled' : item.status_index + 1 !== dataTracking.statuses.length,
          'roadmap__item--active' : item.status_index + 1 === dataTracking.statuses.length
        }"
      >
        <div class="roadmap__infographic">
          <template v-if="item.status_index + 1 === dataTracking.statuses.length">
            <img v-if="dataTracking.delivered" src="../../shared/assets/image/ill_road-success.png" alt="success">
            <img v-else-if="dataTracking.departure" src="../../shared/assets/image/ill_roadtrack.png" alt="truck">
            <img v-else src="../../shared/assets/image/ill_roadstorage.png" alt="storage">
          </template>
        </div>

        <div
            v-if="dataTracking.delivered && item.status_index + 1 === dataTracking.statuses.length"
            class="roadmap__title"
            > {{item.operation_type}}
        </div>

        <template v-else>
          <div class="roadmap__title">{{ item.operation_attr != null ? item.operation_attr : item.operation_type}}</div>
          <div class="roadmap__description"><span> {{ item.region != null ? item.region : 'Россия' }} </span></div>
        </template>

        <div class="roadmap__description"><span> {{ formatDate(item.date) }} </span></div>
      </li>
    </template>

  </ul>
</template>

<script>
import {formattedDate} from "@/shared/libs/formattedDate";
export default {
  name: "ListRoadmap",
  components: {},
  props:{
    skeleton: {
      type: Boolean
    },
    listRoadmap: {
      type: Object,
    }
  },
  computed:{
    reverseRoadmap(){
      if(this.listRoadmap != null){
        const res = this.listRoadmap.statuses
        return res.reverse()
      } else {
        return null
      }
    },
    dataTracking(){
      return this.listRoadmap
    },
    isDelivered(){
      if(this.listRoadmap != null){
        return this.listRoadmap.delivered
      } else {
        return false
      }
    },
  },
  methods:{
    formatDate(date){
      return formattedDate(date)
    },
  }
}
</script>

<style scoped>
.roadmap{
  list-style: none;
  background: url("../../shared/assets/image/ic_circle.svg") repeat-y left 24px top / auto;
}

.roadmap__item{
  position: relative;
  padding-left: 88px;
  margin: 24px 0;
}

.roadmap__title{
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #333333;
  margin-bottom: 4px;
}

.roadmap__item--active{
  margin: 32px 0!important;
}

.roadmap__item--active .roadmap__title{
  font-weight: 600;
}

.roadmap__item--disabled .roadmap__title{
  /*color: #9A9B9A;*/
  font-size: 12px;
}

.roadmap__description{
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #9A9B9A;
  margin: 4px 0;
}

.roadmap__description span{
  display: inline-block;
}

.roadmap__infographic{
  background: #FBFAF9;
  border: 1px solid #D9D9D9;
  width: 16px;
  height: 16px;
  border-radius: 50%;

  position: absolute;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
}

.roadmap__item:last-child .roadmap__infographic{
  top: auto;
  transform: none;
  bottom: 0;
}

.roadmap__item--active .roadmap__infographic{
  width: 75px!important;
  height: 75px!important;
  background-color: #fff!important;
  border: 2px solid rgba(17, 166, 64, 0.7)!important;
  left: -12px!important;
  display: flex!important;
  align-items: center!important;
  justify-content: center!important;
  /*padding-left: 8px;*/
}

.roadmap__item--active .roadmap__infographic img{
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.roadmap__item--active:first-child .roadmap__infographic{
  top: -18px;
}
.roadmap__item--active:last-child .roadmap__infographic{
  top: auto;
  bottom: -18px;
}

.roadmap__item--disabled .roadmap__infographic{
  width: 24px;
  height: 24px;
  left: 14px;
  transform: none;
  border: 0;

  background: #fff url("../../shared/assets/image/ic_warn-good.svg") center / contain;
}

.roadmap__item:first-child .roadmap__infographic{
  top: 0;
  width: 24px;
  height: 24px;
  left: 14px;
  transform: none;
  background: #FBFAF9;
  border: 1px solid #D9D9D9;
}


.roadmap__label{
  font-weight: 600;
  text-transform: uppercase;
  font-size: 12px;
  line-height: 130%;
  color: #9A9B9A;
}

/*.roadmap__item--disabled .roadmap__infographic{*/
/*  border: 1px solid #11A640;*/
/*}*/

/* Скелетон */
.skeleton .roadmap__title{
  width: 100px;
  height: 20px;
  border-radius: 2px;

  background: linear-gradient(90deg, #CFCFCF 0%, #F5F5F5 53.13%, #CECDCD 99.48%);
  background-size: 200%;
}
.skeleton .roadmap__description{
  width: 150px;
  height: 18px;

  background: linear-gradient(90deg, #E1E1E1 0%, #E9E8E8 100%);
  opacity: 50%;
}


</style>