import {createStore} from "vuex";
import storeTracking from '@/entities/tracking/storeTracking'
import storeTariff from "@/entities/tariff/storeTariff";
import storeDefault from "@/shared/storeDefault";

export const store = createStore({
    modules:{
        storeDefault,
        storeTracking,
        storeTariff,
    }
})