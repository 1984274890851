<template>
<div class="base-warn-top">
  <div class="wrap">
    <div class="base-warn-top__container">
      <span class="base-warn-top__icon"></span>
      <p>Данная страница является тестовой, информация на ней неактуальна. Получите актуальную информацию на&nbsp;<base-link :new-window='true' content="box.sibtrans.ru" href="https://box.sibtrans.ru"></base-link></p>
    </div>
  </div>
</div>
</template>

<script>
import BaseLink from "@/shared/ui/BaseLink";
export default {
  name: "BaseWarnTop",
  components: {BaseLink}
}
</script>

<style scoped>
.base-warn-top{
  background: #FFFFEF;
  border-bottom: 1px solid #ECB86A;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: #5B5B5B;
  z-index: 2;
  padding: 8px 32px;
}
.base-warn-top__icon{
  min-width: 20px;
  width: 20px;
  height: 20px;
  background: url("../assets/image/ic_warn-attention.svg") no-repeat center / contain;
  margin-right: 12px;
}
.base-warn-top__container{
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
</style>