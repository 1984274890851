<template>
  <div class="dropdown">
    <div class="headline">
      <slot name="head"></slot>
    </div>
    <transition name="fade">
      <div class="body" v-if="statusAreaOptions">
        <div class="content">
          <slot name="body"></slot>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "BaseDropdown",
  props:{
    commandVisible:{
      type: Boolean,
      require: false
    }
  },
  data(){
    return{
      statusAreaOptions: false,
      opened: null,
    }
  },
  watch:{
    commandVisible(){
      this.handleCommandVisible(this.commandVisible)
    }
  },
  methods:{
    handleCommandVisible(boolean){
      this.visibleAreaOptions(boolean)
    },
    visibleAreaOptions: function (boolean = false){
      this.statusAreaOptions = boolean
    },
  },
  mounted() {
    // document.addEventListener('click', this.handleCommandVisible.bind(this, false), true)
    const onClickOutside = e => this.visibleAreaOptions(this.$el.contains(e.target) && this.commandVisible);
    document.addEventListener('click', onClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.visibleAreaOptions(false))
  },
}
</script>

<style scoped>
.dropdown{
  position: relative;
}
.body{
  background: #FFFFFF;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  position: absolute;
  top: 56px;
  width: 100%;
  z-index: 1;
  padding: 2px 4px 2px 0;
  overflow: hidden;
}
.content{
  padding: 2px 0;
  max-height: 187px;
  overflow: auto;
}

/* scrollbar */
.content::-webkit-scrollbar {
  width: 4px;               /* ширина scrollbar */
}
.content::-webkit-scrollbar-track {
  background: #fff;        /* цвет дорожки */
}
.content::-webkit-scrollbar-thumb {
  background-color: #fff;    /* цвет плашки */
  border-radius: 4px;       /* закругления плашки */
  border: 4px solid #D9D9D9;  /* padding вокруг плашки */
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>