<template>
<div class="page main-loader">
  <div class="logo logo--transparent"></div>
  <div class="logo logo--color"></div>
</div>
</template>

<script>
export default {
  name: "LoaderStart"
}
</script>

<style scoped>
.main-loader{
  width: 100vw;
  height: 120vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
}

.logo{
  width: 200px;
  height: 200px;
  background: no-repeat center / 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
}
.logo--transparent{
  /*background-image: url("../assets/image/logo--opacity.svg");*/
  animation: loader 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  width: 250px;
  height: 250px;
  background: #F3F3F4;
  box-shadow: 0 20px 10px rgba(243, 243, 244, 1);
  filter: blur(2px);
  opacity: .9;
  z-index: 2;
}
.logo--color{
  background-image: url("../assets/image/logo--color.svg");
  z-index: 1;
  /*animation: loader 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;*/
}
@keyframes loader {
  0%{
    transform: translate(-50%, -100%);
  }
  100%{
    transform: translate(-50%, -200%);
  }
}
</style>