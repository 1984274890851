export default {
    mutations: {
        // TODO убрать
        updateParamsTariff(state, obj){
            state.paramsTariff = obj
        },
        updateDeparture(state, obj){
            state.departure = obj
        },
        updateDepartureLocation(state, obj){
            state.departureLocation = obj
        },
        updateDestination(state, obj){
            state.destination = obj
        },
        updateEstimated(state, num){
            state.estimated = num
        },
        updateWeight(state, num){
            state.weight = num
        },
        updateStatePostedParamsTariff(state, flag){
            state.postedParamsTariff = flag
        },
        updateListTariff(state, obj){
            state.listTariff = obj
        },
        waitingResponseTariff(state, flag){
            state.loadingResponseTariff = flag
        },
        updateStateFieldsForCalculate(state, str){
            state.fieldsForCalculate = str
        },
    },
    state: {
        paramsTariff: null,
        departure: null,
        destination: null,
        estimated: null,
        weight: null,
        listTariff: null,
        loadingResponseTariff: false,
        fieldsForCalculate: 'empty', // ready/empty
        postedParamsTariff: false,
        departureLocation: null
    },
    getters: {
        getDeparture(state){
            return state.departure
        },
        getDepartureLocation(state){
            return state.departureLocation
        },
        getDestination(state){
            return state.destination
        },
        getEstimated(state){
            return state.estimated
        },
        getWeight(state){
            return state.weight
        },
        getListTariff(state){
            return state.listTariff
        },
        getTariffErrorMessage(state){
            return state.listTariffErrorMessage
        },
        paramsTariff(state){
            // state.paramsTariff = {
            //     departure: state.departure,
            //     destination: state.destination,
            //     estimated: state.estimated,
            //     weight: state.weight
            // }
            return state.paramsTariff
        },
        stateViewTariff(state){
            return state.viewTariff
        },
        stateFieldsForCalculate(state){
            return state.fieldsForCalculate
        },
        stateLoadingResponseTariff(state){
            return state.loadingResponseTariff
        },
        statePostedParamsTariff(state){
          return state.postedParamsTariff
        }
    }
}