<template>
    <div class="content__headline">
      <base-h3 content="Результат расчета:"></base-h3>
    </div>
    <div class="content__order">
        <div class="container-card-point">
          <card-point
              :point="departure"
              :location="departureLocation"
              type="departure"
          ></card-point>
        </div>
        <div class="container-info">
          <div class="content__headline">
            <base-h3 content="Выберите тариф:"></base-h3>
          </div>
          <!-- Внутренность динамического компонента -->
          <!-- <list-tariff></list-tariff> -->
          <!-- <warn-fields></warn-fields> -->
          <component
              :is="postedParamsTariff"
              :state="postedParamsTariff === 'WarnFields' ? fieldsForCalculate : null"
              :list-tariff="postedParamsTariff === 'ListTariff' ? listTariff : null"
              :loading-response="postedParamsTariff === 'ListTariff' ? loadingResponse : null"
          ></component>
        </div>
        <div class="container-card-point">
          <card-point
              :point="destination"
              :location="null"
              type="destination"
          ></card-point>
        </div>
    </div>
</template>

<script>
import BaseH3 from "@/shared/ui/BaseH3";
import CardPoint from "@/entities/point/CardPoint";
import {mapGetters} from "vuex";
import ListTariff from "@/entities/tariff/ListTariff";
import WarnFields from "@/entities/WarnFields";
export default {
  name: "SectionResultTariff",
  components: {WarnFields, ListTariff, CardPoint, BaseH3},
  computed: {
    ...mapGetters([
      'statePostedParamsTariff',
      'getDeparture',
      'getDepartureLocation',
      'getDestination',
      'getListTariff',
      'stateLoadingResponseTariff',
      'stateFieldsForCalculate']),
    departure(){
      return this.getDeparture != null ? this.getDeparture : null
    },
    departureLocation(){
      return this.getDepartureLocation != null ? this.getDepartureLocation : null
    },
    destination(){
      return this.getDestination != null ? this.getDestination : null
    },
    listTariff(){
      return this.getListTariff
    },
    loadingResponse(){
      return this.stateLoadingResponseTariff
    },
    fieldsForCalculate(){
      return this.stateFieldsForCalculate
    },
    postedParamsTariff(){
      return this.statePostedParamsTariff ? 'ListTariff' : 'WarnFields'
    },
  },
}
</script>

<style scoped>
.container-card-point{
  margin: 16px 0;
}

.container-info{
  margin: 24px 0;
}

.content__headline{
  margin-bottom: 16px;
}
@media screen and (max-width: 900px){
  .content__order,
  .content__body{
    display: flex;
    flex-direction: column;
  }
  .content__order{
    flex: 1;
    justify-content: space-between;
  }
}

</style>